import { useGoogleDSAWizardStore } from '@/modules/campaigns/stores/google/DSA/wizard';
import { useGooglePRXWizardStore } from '@/modules/campaigns/stores/google/PRX/wizard';
import { useMetaDPAWizardStore } from '@/modules/campaigns/stores/meta/DPA/wizard';

export const resetWizardStores = (): void => {
  const googlePRXWizardStore = useGooglePRXWizardStore();
  const googleDSAWizardStore = useGoogleDSAWizardStore();
  const metaDPAWizardStore = useMetaDPAWizardStore();

  googlePRXWizardStore.reset();
  googleDSAWizardStore.reset();
  metaDPAWizardStore.reset();
};
