<template>
  <section>
    <CampaignFormat v-if="!loading" :formats="formats" :campaign="props.campaign" @changeCampaignFormatStatus="onChangeCampaignFormatStatus" @updateFormats="onUpdateFormats" />
    <AssetsSkeleton v-else />
  </section>
</template>
<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { onMounted, ref, watch, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import AssetsSkeleton from '@/modules/campaigns/components/AssetsSkeleton.vue';
import CampaignFormat from '@/modules/campaigns/components/CampaignFormat.vue';
import { CampaignEnum } from '@/modules/campaigns/enums/CampaignEnum';
import { IFormat } from '@/modules/campaigns/interfaces/IFormat';
import { resetWizardStores } from '@/modules/campaigns/utils/resetWizardStores';
import { translateCampaignStatusFromEnumToBoolean } from '@/modules/campaigns/utils/translateCampaignStatusFromEnumToBoolean';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { EnumsMarketingFormat } from '../../../../generated/api';

const props = defineProps({
  campaign: {
    type: String as PropType<CampaignEnum>,
    required: false,
    default: CampaignEnum.GOOGLE,
  },
});

const loading = ref(false);

const toast = useToast();
const { t } = useI18n();
const eshopStore = useEshopsStore();

const googleFormats = [
  {
    type: EnumsMarketingFormat.Pla,
    name: t('campaigns.googleShopping'),
    imageUrl: '/campaignFormats/pla.png',
  },
  {
    type: EnumsMarketingFormat.Prx,
    name: t('campaigns.performanceMax'),
    imageUrl: '/campaignFormats/prx.png',
  },
  {
    type: EnumsMarketingFormat.Dsa,
    name: t('campaigns.google.dsa.editTitle'),
    imageUrl: '/campaignFormats/dsa.png',
  },
];

const metaFormats = [
  {
    type: EnumsMarketingFormat.Dpa,
    name: t('campaigns.meta.dpa.editTitle'),
    imageUrl: '/campaignFormats/dsa.png',
  },
];

const microsoftFormats = [
  {
    type: EnumsMarketingFormat.Dsa,
    name: t('campaigns.meta.dpa.editTitle'),
    imageUrl: '/campaignFormats/dsa.png',
  },
];

const formats = ref();

onMounted(async () => {
  await initData();
});

const initData = async () => {
  loading.value = true;
  await setFormats();
  await loadFormatsInfo();
};

const setFormats = async () => {
  switch (props.campaign) {
    case CampaignEnum.GOOGLE:
      formats.value = googleFormats;
      break;
    case CampaignEnum.META:
      formats.value = metaFormats;
      break;
    case CampaignEnum.MICROSOFT:
      formats.value = microsoftFormats;
      break;
    default:
      formats.value = [];
  }
};

const getFormatStatus = async (format) => {
  try {
    const { data: campaignStatus } = await api.clientCampaignsGetStatus(format);
    return { status: translateCampaignStatusFromEnumToBoolean(campaignStatus.desiredStatus), problem: campaignStatus.problemDetail };
  } catch (error) {
    toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError') });
    return { status: false, problem: 'error' };
  }
};

const getDailyBudget = async (format) => {
  try {
    const { data } = await api.clientCampaignsGetDailyBudget(format);
    return data;
  } catch (error) {
    toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError') });
    return null;
  }
};

const getCampaignExist = async (format) => {
  try {
    const { data } = await api.clientCampaignsGetCampaignStatus(format);
    return data.campaignExists;
  } catch (error) {
    toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError') });
    return false;
  }
};

const loadFormatsInfo = async () => {
  try {
    const formatStatuses = await Promise.all(formats.value.map((format) => getFormatStatus(format.type)));
    const dailyBudgets = await Promise.all(formats.value.map((format) => getDailyBudget(format.type)));
    const campaignExists = await Promise.all(formats.value.map((format) => getCampaignExist(format.type)));

    formats.value.forEach((format, index) => {
      format.formatStatus = formatStatuses[index].status;
      format.problemDetail = formatStatuses[index].problem;
      format.budget = dailyBudgets[index];
      format.isCampaignExist = format === EnumsMarketingFormat.Pla ? true : campaignExists[index];
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loading.value = false;
  }
};

const onChangeCampaignFormatStatus = async (format: IFormat) => {
  const index = formats.value.findIndex((item) => item.type === format.type);
  formats.value[index].formatStatus = format.formatStatus;
  formats.value[index].problemDetail = format.problemDetail;
};

const onUpdateFormats = async (format: IFormat) => {
  const index = formats.value.findIndex((item) => item.type === format.type);
  formats.value[index] = format;
};

watch(
  () => eshopStore.selectedEshop.id,
  async () => {
    resetWizardStores();
    await initData();
  }
);

watch(
  () => props.campaign,
  async () => {
    resetWizardStores();
    await initData();
  }
);
</script>
