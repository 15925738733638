<template>
  <Dialog v-model:visible="showDailyBudget" modal header="" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header>
      <span> </span>
    </template>
    <div>
      <div class="relative mb-4 rounded-md p-2 shadow">
        <div
          v-if="!format.formatStatus"
          v-tooltip.bottom="{
            value: $t('dailyBudget.campaignMustBeTurnedOn'),
            class: 'sm:min-w-[31.25rem] text-center',
            escape: false,
          }"
          class="absolute inset-0 z-10 h-full w-full cursor-help rounded-md bg-gray-100 opacity-70"
        ></div>
        <div class="mb-2 flex items-center self-center">
          <p class="mr-2 block text-xs text-gray-500">
            {{ $t('statistics.pages.statistics.campaignsTypesText') }}
          </p>
          <LockOpenIcon class="block h-8 w-8 text-lime-600 sm:h-5 sm:w-5"></LockOpenIcon>
        </div>
        <div v-if="minimalDailyBudget" class="justify-between sm:flex">
          <div class="">
            <label for="daily-budget" class="mr-2 text-sm text-main">{{ $t('dailyBudget.optimalBudget') }}</label>
            <div class="mb-4 flex items-center sm:mb-0">
              <Dropdown v-model="selectedType" :options="types" optionLabel="label" class="mr-2 !h-10 !w-40">
                <template #value="slotProps">
                  <div class="align-items-center flex items-center">
                    <span class="my-auto text-xs text-main">{{ slotProps.value.label }}</span>
                    <ExclamationTriangleIcon v-if="slotProps.value.value === 'custom'" class="ml-2 h-5 w-5 text-yellow-500" aria-hidden="true" />
                  </div>
                </template>
                <template #option="slotProps">
                  <div class="align-items-center flex items-center" style="border-radius: 35px; margin-right: 5px">
                    <span class="text-sm text-gray-900">{{ slotProps.option.label }}</span>
                    <ExclamationTriangleIcon v-if="slotProps.option.value === 'custom'" class="ml-2 h-5 w-5 text-yellow-500" aria-hidden="true" />
                  </div>
                </template>
              </Dropdown>
              <InputNumber
                v-if="selectedType?.value === 'custom'"
                id="daily-budget"
                v-model="dailyBudget"
                mode="currency"
                :currency="minimalDailyBudget.currency"
                :locale="userStore.getLanguageCode"
                class="!h-9 !w-24"
                :step="1"
                :maxFractionDigits="0"
                :ptOptions="{ mergeProps: true }"
                :pt="{ inputtext: '!text-xs !text-main' }"
                :min="minimalDailyBudget.value"
              />
            </div>
          </div>
        </div>
        <div class="mt-2 flex items-center text-gray-500">
          <label for="daily-budget" class="block text-sm">{{ $t('dailyBudget.minimalBudget') }}</label>
          <span class="ml-2 text-sm sm:text-right">
            <LocalizedValue :value="minimalDailyBudget" />
          </span>
        </div>
      </div>
    </div>
    <template #footer>
      <MainButton @click="cancel()" :text="$t('dailyBudget.cancel')" :hover="false" class="mr-2 rounded-lg hover:opacity-70" />
      <MainButton @click="save(false)" :text="$t('dailyBudget.save')" :hover="false" class="rounded-lg hover:opacity-70" />
    </template>
  </Dialog>
  <Dialog v-model:visible="visibleWarning" modal header="" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header>
      <span> </span>
    </template>
    <div>
      <p class="text-left text-base font-bold text-main">{{ $t('dailyBudget.dialogWarning') }}</p>
    </div>
    <template #footer>
      <Button @click="cancel()" type="button" class="mr-2" severity="warning" :label="$t('dailyBudget.dialogReallyCancel')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
      <Button @click="save(true)" type="button" severity="success" :label="$t('dailyBudget.dialogReallySave')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import { LockOpenIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import { useToast } from 'primevue/usetoast';
import { computed, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { IFormat } from '@/modules/campaigns/interfaces/IFormat';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';

const showDailyBudget = defineModel<boolean>({ required: true });

const props = defineProps({
  format: {
    type: Object as PropType<IFormat>,
    required: false,
    default: null,
  },
});

const emit = defineEmits(['updateDailyBudget']);

const { t } = useI18n();

const toast = useToast();

const loadingStore = useLoadingStore();
const userStore = useUserStore();

const initBudget = ref();
const dailyBudget = ref();
const minimalDailyBudget = ref();

const visibleWarning = ref(false);

const types = computed(() => [
  { label: t('dailyBudget.automatic'), value: 'automatic' },
  { label: t('dailyBudget.custom'), value: 'custom' },
]);

const selectedType = ref(types.value[0]);

watch(
  () => props.format,
  () => {
    initData();
  }
);

const initData = () => {
  initBudget.value = props.format?.budget.dailyBudget;
  dailyBudget.value = props.format?.budget.dailyBudget;
  minimalDailyBudget.value = props.format?.budget.minimalDailyBudget;

  // If daily budget is null, set it to minimal daily budget
  if (dailyBudget.value === null) {
    dailyBudget.value = minimalDailyBudget.value.value;
  }

  // If daily budget is equal to minimal daily budget, set type to automatic
  if (dailyBudget.value === minimalDailyBudget.value.value) {
    selectedType.value = types.value.find((type) => type.value === 'automatic');
  } else {
    selectedType.value = types.value.find((type) => type.value === 'custom');
  }
};

const save = async (forceSave: boolean) => {
  if (!forceSave && selectedType.value?.value === 'custom') {
    visibleWarning.value = true;
    return;
  }

  console.log('save', dailyBudget.value, selectedType.value?.value);

  const payload = {
    dailyBudgetValue: selectedType.value?.value === 'automatic' ? null : dailyBudget.value,
  };

  try {
    await api.clientCampaignsSetDailyBudget(props.format?.type, payload);
    toast.add({
      severity: 'success',
      summary: t('dailyBudget.budgetSaved'),
      life: 6000,
    });

    const updatedFormat = {
      ...props.format,
      budget: {
        ...props.format.budget,
        dailyBudget: selectedType.value?.value === 'automatic' ? null : dailyBudget.value,
      },
    };

    showDailyBudget.value = false;

    emit('updateDailyBudget', updatedFormat);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
    visibleWarning.value = false;
  }
};

const cancel = () => {
  dailyBudget.value = initBudget.value;
  if (dailyBudget.value === minimalDailyBudget.value.value) {
    selectedType.value = types.value.find((type) => type.value === 'automatic');
  } else {
    selectedType.value = types.value.find((type) => type.value === 'custom');
  }
  showDailyBudget.value = false;
  visibleWarning.value = false;
};
</script>
