<template>
  <main class="grid h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div v-if="!callbackError" class="text-center">
      <p class="mt-6 text-base leading-7 text-gray-600">{{ $t('authentication.socialCallback.text') }}</p>
    </div>
    <div v-else class="bg-white dark:bg-mainDark">
      <main class="mx-auto w-full max-w-7xl px-6 lg:px-8">
        <img class="mx-auto h-10 w-auto sm:h-12" src="/favicon.png" alt="tanganica" />
        <div class="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
          <p v-if="callbackError === ErrorEnum.USER_ALREADY_REGISTERED" class="mt-1 text-3xl font-bold tracking-tight text-red-600 sm:text-3xl">{{ $t('authentication.socialCallback.errorText1') }}</p>
          <p v-else class="mt-1 text-3xl font-bold tracking-tight text-red-600 sm:text-3xl">{{ $t('authentication.socialCallback.errorText2') }}</p>
          <p v-if="callbackError === ErrorEnum.USER_ALREADY_REGISTERED" class="mt-4 text-base leading-7 text-main sm:mt-6 sm:text-lg sm:leading-8">
            {{ $t('authentication.socialCallback.errorUserAlreadyExists') }}
          </p>
          <p v-else class="mt-4 text-base leading-7 text-main sm:mt-6 sm:text-lg sm:leading-8">{{ $t('authentication.socialCallback.serverError') }}</p>
        </div>
        <div class="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
          <ul role="list" class="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
            <li class="relative flex gap-x-6 py-6">
              <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                <UserPlusIcon class="h-6 w-6 text-main" aria-hidden="true" />
              </div>
              <div class="flex-auto">
                <h3 class="text-sm font-semibold leading-6 text-main">
                  <router-link :to="{ name: PUBLIC_ROUTES.REGISTER_PAGE }">
                    <span class="absolute inset-0" aria-hidden="true" />
                    {{ $t('authentication.socialCallback.backToRegistration') }}
                  </router-link>
                </h3>
                <p class="mt-2 text-sm leading-6 text-main">{{ $t('authentication.socialCallback.backToRegistrationText') }}</p>
              </div>
              <div class="flex-none self-center">
                <ChevronRightIcon class="h-5 w-5 text-main" aria-hidden="true" />
              </div>
            </li>
            <li class="relative flex gap-x-6 py-6">
              <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                <IdentificationIcon class="h-6 w-6 text-main" aria-hidden="true" />
              </div>
              <div class="flex-auto">
                <h3 class="text-sm font-semibold leading-6 text-main">
                  <router-link :to="{ name: PUBLIC_ROUTES.LOGIN_PAGE }">
                    <span class="absolute inset-0" aria-hidden="true" />
                    {{ $t('authentication.socialCallback.backToLogin') }}
                  </router-link>
                </h3>
                <p class="mt-2 text-sm leading-6 text-main">{{ $t('authentication.socialCallback.backToLoginText') }}</p>
              </div>
              <div class="flex-none self-center">
                <ChevronRightIcon class="h-5 w-5 text-main" aria-hidden="true" />
              </div>
            </li>
          </ul>
        </div>
      </main>
    </div>
  </main>
</template>
<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { UserPlusIcon, IdentificationIcon, ChevronRightIcon } from '@heroicons/vue/24/solid';
import { useJwt } from '@vueuse/integrations/useJwt';
import { usePrimeVue } from 'primevue/config';
import { onMounted, ref } from 'vue';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import { PUBLIC_ROUTES } from '@/enums/Routes/PublicRoutesEnum';
import { ErrorEnum } from '@/modules/authentication/enums/ErrorEnum';
import { api } from '@/services/api';
import { useLoadingStore } from '@/stores/loading';
import { handleLoginAndEshopList } from '@/utils/handleLoginAndEshopList';
import { EnumsExternalAuthProvider } from '../../../../generated/api';

const url = new URL(window.location.href);
const state = url.searchParams.get('state');
const code = url.searchParams.get('code');
const gtm = useGtm();

const loadingStore = useLoadingStore();
const primeVue = usePrimeVue();

const callbackError = ref();

const props = defineProps({
  provider: {
    type: String,
    required: true,
  },
});

onMounted(async () => {
  loadingStore.updateLoading(true);

  const payload = {
    provider: (props.provider.charAt(0).toUpperCase() + props.provider.slice(1)) as EnumsExternalAuthProvider,
    code,
  };

  try {
    const { data } = await api.clientCallbacksExternalAuthCallback(payload, {
      headers: {
        Authorization: `Bearer ${state}`,
      },
      metadata: {
        requestType: RequestTypeEnum.PUBLIC,
      },
    });

    // Set gtm event

    const { payload: decodedState } = useJwt(state);
    const { payload: decodedToken } = useJwt(data.accessToken);
    if (decodedState.value.IsRegister === 'True') {
      const params = {
        email: decodedToken.value.email,
        provider: (props.provider.charAt(0).toUpperCase() + props.provider.slice(1)) as EnumsExternalAuthProvider,
      };
      gtm?.trackEvent({
        event: 'gtm.registerForm',
        status: 'OK',
        value: {
          registerData: params,
        },
      });
    }

    await handleLoginAndEshopList(primeVue, data.accessToken, data.refreshToken);
  } catch (error: any) {
    if (error.response) {
      if (error.response.status >= 400 && error.response.status < 500) {
        callbackError.value = ErrorEnum.USER_ALREADY_REGISTERED;
      } else {
        callbackError.value = ErrorEnum.SERVER_ERROR;
      }
    } else {
      callbackError.value = ErrorEnum.SERVER_ERROR;
    }
  } finally {
    loadingStore.updateLoading(false);
  }
});
</script>
