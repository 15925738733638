<template>
  <div class="flex h-full w-full flex-col overflow-hidden rounded-xl bg-white px-5 py-3 shadow">
    <div class="flex w-full justify-end">
      <RouterLink
        :to="{
          name: CAMPAIGNS_ROUTES.FORMATS,
          params: {
            campaign: props.campaign,
          },
        }"
        ><XCircleIcon class="h-6 w-6"></XCircleIcon
      ></RouterLink>
    </div>
    <div class="mb-10 mt-1 flex h-10 w-full items-center justify-between gap-10 px-5">
      <div class="w-full items-center gap-5">
        <img class="h-6 md:h-10" :src="$filePath + header?.image" alt="Google" />
        <h1 class="text-xl font-bold text-[#F67424] md:text-2xl">{{ header?.headline }}</h1>
      </div>
      <Steps v-if="props.editMode === EditModeEnum.WIZARD" :format="props.format" :campaign="props.campaign"></Steps>
    </div>
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Suspense timeout="0">
          <template #default>
            <component :is="Component"></component>
          </template>
          <template #fallback>
            <ProgressSpinner class="flex h-full flex-col" />
          </template>
        </Suspense>
      </template>
    </RouterView>
  </div>
  <Dialog v-model:visible="confirmLeaveDialog" modal :draggable="false">
    <template #header><span> </span></template>
    <div class="flex flex-col items-center text-center text-main">{{ $t('campaigns.confirmLeaveDialogTitle') }}</div>
    <template #footer>
      <div class="mx-auto flex flex-col items-center justify-between gap-x-2 gap-y-4 md:w-[25rem] md:flex-row md:gap-y-0">
        <MainButton @click="onCancel()" :hover="false" class="mx-auto min-w-[12.5rem] bg-main font-bold hover:bg-mainhover" type="button" :text="t('campaigns.confirmLeaveDialogFalse')"></MainButton>
        <MainButton @click="onConfirm()" :hover="false" class="mx-auto min-w-[12.5rem] bg-orange-700 font-bold hover:bg-orange-600" type="button" :text="t('campaigns.confirmLeaveDialogTrue')"></MainButton>
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import { XCircleIcon } from '@heroicons/vue/24/outline';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref, watch, PropType, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, RouterLink, RouterView, useRouter } from 'vue-router';
import Steps from '@/modules/campaigns/components/Steps.vue';
import { CampaignEnum } from '@/modules/campaigns/enums/CampaignEnum';
import { EditModeEnum } from '@/modules/campaigns/enums/EditModeEnum';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { loadWizardStore } from '@/modules/campaigns/utils/loadWizardStore';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { findLastIndex } from '@/utils/findLastIndex';
import { EnumsAssetType, EnumsMarketingFormat } from '../../../../generated/api';

const router = useRouter();
const loadingStore = useLoadingStore();
const toast = useToast();
const { t } = useI18n();
const eshopStore = useEshopsStore();

const props = defineProps({
  campaign: {
    type: String as PropType<CampaignEnum>,
    required: true,
  },
  format: {
    type: String as PropType<EnumsMarketingFormat>,
    required: true,
  },
  editMode: {
    type: String as PropType<EditModeEnum>,
    required: false,
    default: EditModeEnum.WIZARD,
  },
});

const store = await loadWizardStore(props.campaign, props.format);

watch(
  () => eshopStore.selectedEshop.id,
  async () => {
    store.reset();
    await router.push({
      name: CAMPAIGNS_ROUTES.FORMATS,
      params: {
        campaign: props.campaign,
      },
    });
  }
);

onMounted(async () => {
  await initData();
  if (props.editMode === EditModeEnum.WIZARD) {
    await redirectToActiveStep();
  }
});

const validateData = () => {
  switch (props.format) {
    case EnumsMarketingFormat.Prx:
      validatePrxData();
      break;
    case EnumsMarketingFormat.Dpa:
      validateDpaData();
      break;
  }
};

const initData = async () => {
  loadingStore.updateLoading(true);
  try {
    const { data } = await api.clientCampaignsGetAssets(props.format);

    if (props.format === EnumsMarketingFormat.Prx) {
      store.steps[0].values.images = data.assetUrls.filter((asset) => asset.type === EnumsAssetType.PrxImage);
      store.steps[0].values.logos = data.assetUrls.filter((asset) => asset.type === EnumsAssetType.PrxLogo);
    }

    let assetType;

    switch (props.format) {
      case EnumsMarketingFormat.Prx:
        assetType = EnumsAssetType.PrxTxtJson;
        break;
      case EnumsMarketingFormat.Dsa:
        assetType = EnumsAssetType.DsaTxtJson;
        break;
      case EnumsMarketingFormat.Dpa:
        assetType = EnumsAssetType.DpaTxtJson;
        break;
    }

    const jsonResponse = data.assetUrls.filter((asset) => asset.type === assetType);

    if (jsonResponse[0]?.url) {
      await getJson(jsonResponse[0].url);
    }

    if (props.editMode === EditModeEnum.WIZARD) {
      validateData();
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const redirectToActiveStep = async () => {
  const lastValidStepIndex = findLastIndex(store.steps, (step) => step.isValid);

  if (lastValidStepIndex === -1) {
    await router.push({
      name: store.steps[0]?.route.name,
      params: store.steps[0]?.route.params,
    });
  }
  await router.push({
    name: store.steps[lastValidStepIndex + 1]?.route.name,
    params: store.steps[lastValidStepIndex + 1]?.route.params,
  });
};

const getJson = async (url) => {
  const response = await fetch(url);
  const reader = response.body?.getReader();
  try {
    const readerData = await reader?.read();
    // save json to variable
    const jsonString = new TextDecoder('utf-8').decode(readerData?.value);
    // create from string json
    const parseJsonString = JSON.parse(jsonString);

    switch (props.format) {
      case EnumsMarketingFormat.Prx:
        mapPrxJson(parseJsonString);
        break;
      case EnumsMarketingFormat.Dsa:
        mapDsaJson(parseJsonString);
        break;
      case EnumsMarketingFormat.Dpa:
        mapDpaJson(parseJsonString);
        break;
    }
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: t('serverErrorTitle'),
      detail: '',
      life: 6000,
    });
  }
};

const header = computed(() => {
  let image = '';
  let headline = '';

  if (props.campaign === CampaignEnum.GOOGLE) {
    image = '/logos/google.png';

    switch (props.format) {
      case EnumsMarketingFormat.Prx:
        headline = t('campaigns.google.prx.editHeadline');
        break;
      case EnumsMarketingFormat.Dsa:
        headline = t('campaigns.google.dsa.editTitle');
        break;
      default:
        headline = '';
    }

    return { image, headline };
  } else if (props.campaign === CampaignEnum.META) {
    image = '/logos/meta.png';

    switch (props.format) {
      case EnumsMarketingFormat.Dpa:
        headline = t('campaigns.meta.dpa.editTitle');
        break;
      default:
        headline = '';
    }

    return { image, headline };
  }
});

// DSA
const mapDsaJson = (json) => {
  Object.keys(store.steps[0].values).forEach((key) => {
    store.steps[0].values[key] = json[key] ?? '';
  });
};

// PRX
const mapPrxJson = (json) => {
  Object.keys(store.steps[1].values).forEach((key) => {
    store.steps[1].values[key] = json[key] ?? '';
  });
  Object.keys(store.steps[2].values).forEach((key) => {
    store.steps[2].values[key] = json[key] ?? '';
  });
};

const validatePrxAssets = () => {
  store.steps[0].isValid = store.steps[0].values.images.length >= 3 && store.steps[0].values.logos.length > 0;
};

const validatePrxDescriptions = () => {
  const ShortDescription = store.steps[2].values.ShortDescription;
  const LongDescriptions = [store.steps[2].values.LongDescription1, store.steps[2].values.LongDescription2, store.steps[2].values.LongDescription3, store.steps[2].values.LongDescription4];
  const isShortDescriptionValid = ShortDescription !== null && ShortDescription.length > 0;
  const validLongDescriptionsCount = LongDescriptions.filter((description) => description !== null && description.length > 0).length;

  store.steps[2].isValid = isShortDescriptionValid && validLongDescriptionsCount >= 1;
};

const validatePrxHeadlines = () => {
  const LongHeadline = store.steps[1].values.LongHeadline;

  const AllShortHeadlines = [store.steps[1].values.ShortHeadline1, store.steps[1].values.ShortHeadline2, store.steps[1].values.ShortHeadline3, store.steps[1].values.ShortHeadline4, store.steps[1].values.ShortHeadline5];

  // Check that LongHeadline is not null and not empty
  const isLongHeadlineValid = LongHeadline !== null && LongHeadline.length > 0;
  // Check that all ShortHeadlines are not null and count how many are non-empty
  const validShortHeadlinesCount = AllShortHeadlines.filter((headline) => headline !== null && headline.length > 0).length;
  // Check if LongHeadline is valid and at least 3 ShortHeadlines are non-empty
  store.steps[1].isValid = isLongHeadlineValid && validShortHeadlinesCount >= 3;
};

const validatePrxData = () => {
  validatePrxAssets();
  validatePrxHeadlines();
  validatePrxDescriptions();
};

// DPA
const mapDpaJson = (json) => {
  Object.keys(store.steps[1].values).forEach((key) => {
    store.steps[1].values[key] = json[key] ?? '';
  });
};

const validateDpaDescription = () => {
  store.steps[1].isValid = store.steps[1].values.FirstHeadline !== null && store.steps[1].values.FirstHeadline.length > 0;
};

const validateDpaData = () => {
  validateDpaDescription();
};

const confirmLeaveDialog = ref<boolean>(false);
const nextRoute = ref();

onBeforeRouteLeave((to, from, next) => {
  if (store.isDirty) {
    confirmLeaveDialog.value = true;
    nextRoute.value = to;
  } else {
    next();
  }
});

const onCancel = () => {
  confirmLeaveDialog.value = false;
  nextRoute.value = null;
};

const onConfirm = () => {
  confirmLeaveDialog.value = false;
  store.reset();
  router.push({ name: nextRoute.value.name });
  nextRoute.value = null;
};
</script>
