<template>
  <div class="mb-2 flex justify-end">
    <div>
      <div class="mb-2 block w-full min-w-fit border-none px-0 dark:bg-[#28334e] lg:w-72">
        <select v-model="selectDays" id="location" name="location" class="mx-auto mt-1 w-full min-w-fit rounded-full border-gray-300 lg:w-72">
          <option value="today">
            {{ $t('statistics.pages.statistics.today') }}
          </option>
          <option value="yesterday">
            {{ $t('statistics.pages.statistics.yesterday') }}
          </option>
          <option value="last7Days">
            {{ $t('statistics.pages.statistics.last7Days') }}
          </option>
          <option value="lastMonth">
            {{ $t('statistics.pages.statistics.lastMonth') }}
          </option>
          <option value="last3Month">
            {{ $t('statistics.pages.statistics.last3Month') }}
          </option>
          <option value="last6Month">
            {{ $t('statistics.pages.statistics.last6Month') }}
          </option>
          <option value="lastYear">
            {{ $t('statistics.pages.statistics.lastYear') }}
          </option>
        </select>
      </div>
      <Datepicker
        class="relative block w-full min-w-fit rounded-full border-none px-0 dark:bg-[#28334e] lg:w-72"
        :lang="userStore.languageCode"
        :locale="userStore.languageCode"
        :format="userStore.languageCode == 'cs' ? 'dd.MM.yyyy' : 'MM/dd/yyyy'"
        type="date"
        :selectText="$t('statistics.pages.statistics.select')"
        :cancelText="$t('statistics.pages.statistics.cancel')"
        :clearable="false"
        v-model="dates"
        :disabled-dates="disabledDates"
        :enable-time-picker="true"
        :auto-position="false"
        range
        :teleport="true"
      >
      </Datepicker>
    </div>
  </div>

  <!-- Categories -->
  <div class="mb-4 flex w-fit items-center">
    <h1 class="text-base font-bold text-main sm:text-lg">
      {{ $t('statistics.pages.statistics.exportText') }}
    </h1>
    <StatisticsExport :dates="{ dateFrom: dates[0], dateTo: dates[1] }" statisticsType="categories" :showPDF="false" :filters="lazyParamsCategoriesStats" :totalRows="totalRecordsCategories" />
  </div>
  <DataTable
    :key="'categories'"
    v-model:filters="categoriesStatsFilters"
    :value="categoriesStats"
    filterDisplay="row"
    dataKey="id"
    showGridlines
    stripedRows
    size="small"
    paginator
    :rows="10"
    scrollable
    :rowsPerPageOptions="[5, 10, 20, 50, 100]"
    :loading="loading"
    :totalRecords="totalRecordsCategories"
    :lazy="true"
    @page="onPageCategories($event)"
    @sort="onSortCategories($event)"
    @filter="onFilterCategories($event)"
    ref="categoriesStatsTable"
    :first="first"
    :ptOptions="{ mergeProps: true }"
    :pt="{
      column: {
        headercell: ({ context, props }) => ({
          class: [''],
        }),
      },
    }"
  >
    <template #empty>
      <div class="my-auto">
        <h1 class="my-auto block text-center text-xl font-bold text-main">{{ $t('statistics.pages.statistics.dataNotFound') }}</h1>
      </div>
    </template>
    <Column sortable field="category" :header="$t('statistics.pages.statistics.categoriesCategory')">
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown
          v-model="filterModel.value"
          @change="filterCallback()"
          optionValue="value"
          optionLabel="label"
          :options="allCategories"
          class="p-column-filter"
          style="min-width: 12rem"
          :showClear="false"
          :pt-options="{ mergeProps: true }"
          :pt="{
            input: () => ({
              class: ['sm:!p-1 !text-xs'],
            }),
          }"
        >
          <template #option="slotProps">
            <span class="cursor-pointer whitespace-nowrap py-4 text-sm text-gray-500 dark:text-[#cbd5e1]">
              <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/10"> {{ slotProps.option.value }} </span>
            </span>
          </template>
        </Dropdown>
      </template>
    </Column>
    <Column sortable field="revenue" :header="$t('statistics.pages.statistics.categoriesRevenue')">
      <template #body="slotProps">
        <span v-if="slotProps.data.revenue === null" class="mx-auto text-center">-</span>
        <LocalizedValue v-else :value="slotProps.data.revenue"></LocalizedValue>
      </template>
    </Column>
    <Column sortable field="quantity" :header="$t('statistics.pages.statistics.categoriesQuantity')"></Column>
    <Column sortable field="orderCount" :header="$t('statistics.pages.statistics.categoriesOrderCount')"></Column>
  </DataTable>
</template>
<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker';
import { FilterMatchMode } from 'primevue/api';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import StatisticsExport from '@/modules/statistics/components/StatisticsExport.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';
import { calculateDateRange } from '@/utils/statisticsCalculateDateRange';

const categoriesStatsTable = ref();
const lazyParamsCategoriesStats = ref({});

const loading = ref(false);
const totalRecordsCategories = ref(0);
const first = ref(0);
const userStore = useUserStore();
const eshopStore = useEshopsStore();
const selectDays = ref('lastMonth');
const disabledDates = ref([]);
const allCategories = ref([]);

const categoriesStats = ref();
const toast = useToast();
const today = new Date();
const dates = ref([new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0, 0), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)]);

const categoriesStatsFilters = ref({
  category: {
    value: '',
    matchMode: FilterMatchMode.CONTAINS,
  },
});

onMounted(async () => {
  loading.value = true;

  lazyParamsCategoriesStats.value = {
    dateFrom: dates.value[0], // TO DO: SET THE PRODUCTION DATE
    dateTo: dates.value[1], // TO DO: SET TODAY DATE
    show: 'all', // 'all', 'active', 'newRegister', 'archive
    first: categoriesStatsTable.value.first,
    rows: categoriesStatsTable.value.rows,
    sortField: null,
    sortOrder: null,
    filters: categoriesStatsFilters.value,
    page: 0,
  };

  setDisableDates();
  await loadLazyCategoriesData();
});

const loadLazyCategoriesData = async (event?) => {
  loading.value = true;
  lazyParamsCategoriesStats.value = {
    ...lazyParamsCategoriesStats.value,
    first: event?.first || first.value,
    utcOffset: new Date().getTimezoneOffset() / -60,
  };
  lazyParamsCategoriesStats.value.dateFrom = dates.value[0];
  lazyParamsCategoriesStats.value.dateTo = dates.value[1];
  try {
    const { data } = await api.clientStatisticsGetProductCategoryStats(lazyParamsCategoriesStats.value);
    categoriesStats.value = data.stats;
    totalRecordsCategories.value = data.totalRows || 0;
    if (data.categoryList && data.categoryList.length > 0) {
      allCategories.value = [];
      data.categoryList.forEach((category) => {
        allCategories.value.push({ label: category, value: category });
      });
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loading.value = false;
  }
};

const onPageCategories = (event) => {
  lazyParamsCategoriesStats.value = { ...lazyParamsCategoriesStats.value, ...event };
  loadLazyCategoriesData(event);
};

const onSortCategories = (event) => {
  lazyParamsCategoriesStats.value = { ...lazyParamsCategoriesStats.value, ...event };
  loadLazyCategoriesData(event);
};

const onFilterCategories = (event) => {
  lazyParamsCategoriesStats.value.filters = categoriesStatsFilters.value;
  loadLazyCategoriesData(event);
};

const setDisableDates = () => {
  const today = new Date();
  for (let i = 1; i < 32; i++) {
    disabledDates.value.push(new Date(new Date().setDate(today.getDate() + i)));
  }
};

watch(
  () => eshopStore.getSelectedEshop?.id,
  async () => {
    await loadLazyCategoriesData();
  }
);

watch(
  () => dates.value,
  async (val) => {
    // watch the date
    if (dates.value && dates.value.length === 2) {
      await loadLazyCategoriesData();
    }
  }
);

watch(
  () => selectDays.value,
  async (selectedValue) => {
    const { startDate, endDate } = calculateDateRange(selectedValue);

    dates.value = [startDate, endDate];
    await loadLazyCategoriesData();
  }
);
</script>
