<template>
  <Combobox as="div" :class="[onWizard ? 'max-w-fit' : 'sm:max-w-sm', 'absolute inset-x-0 mx-auto w-80 sm:w-auto lg:inset-0 lg:top-4 lg:m-auto']" :modelValue="selectedEshop" @update:modelValue="onEshopChange">
    <div class="relative mt-1">
      <div class="relative inline-block w-auto sm:w-80">
        <div class="sentry-unmask flex w-[13.75rem] gap-0.5 rounded-full border bg-white py-2 pl-3 pr-10 text-xs lowercase shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 dark:bg-[#28334e] sm:w-80 sm:text-sm">
          <div class="truncate">{{ selectedEshop.domainName }}</div>
          <img v-if="selectedEshop.country" :src="`${$filePath}/flags/${selectedEshop.country}.png`" alt="..." class="inline-block h-3 w-3 sm:h-5 sm:w-5" />
          <img v-if="selectedEshop.isAffil" :src="`${$filePath}/icons/affiliate.png`" alt="..." class="ml-1 inline-block h-3 w-auto sm:h-4" />
          <ChevronUpDownIcon class="absolute right-0 mr-2 inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <ComboboxButton class="absolute inset-y-0 right-0 mr-2 w-full items-center rounded-r-md px-2 focus:outline-none sm:w-80"> </ComboboxButton>

        <ComboboxOptions
          v-if="managedEshops.length > 0"
          class="scrollbar ring-1/5 absolute z-50 mt-1 max-h-56 w-[15.625rem] overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-black focus:outline-none dark:bg-[#28334e] dark:text-[#cbd5e1] sm:w-80 sm:text-sm"
        >
          <ComboboxOption v-for="eshop in managedEshops" :key="eshop.id" :value="eshop" as="template" v-slot="{ active, selected }">
            <li :class="['cursor-default select-none py-2 pl-3', active ? 'bg-indigo-600 text-white dark:text-[#cbd5e1]' : 'text-gray-900 dark:text-[#cbd5e1]', selected ? 'bg-indigo-600 text-white' : '']">
              <span :class="['sentry-unmask flex w-[13.75rem] gap-2 py-2 pl-3 pr-8 sm:w-80', selected && 'font-semibold']">
                <span class="truncate text-xs lowercase sm:text-sm">{{ eshop.domainName }}</span>
                <img v-if="eshop.country" :src="`${$filePath}/flags/${eshop.country}.png`" alt="..." class="h-3 w-3 sm:h-5 sm:w-5" />
                <img v-if="eshop.isAffil" :src="`${$filePath}/icons/affiliate.png`" alt="..." class="ml-1 h-3 w-auto sm:h-4" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </div>
      <!-- ADD ESHOP -->
      <div @click="redirectToWizard()" class="group relative ml-1 inline-block w-6 cursor-pointer">
        <PlusIcon class="relative inline-flex w-6 cursor-pointer hover:text-blue-600 md:hidden"> </PlusIcon>
        <button class="btn relative ml-2 hidden w-16 cursor-pointer justify-center overflow-hidden rounded-full bg-button_main px-1 py-3 font-medium text-white shadow-sm sm:py-2 sm:text-sm md:inline-flex md:w-28 lg:w-24">
          <span class="absolute inset-0 h-full rounded-full bg-button_main_hover opacity-90"></span>
          <span class="z-50 block text-[0.813rem]">{{ $t('global.selectEshop.addEshop') }}</span>
        </button>
      </div>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import { Combobox, ComboboxButton, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { ChevronUpDownIcon, PlusIcon } from '@heroicons/vue/24/solid';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import { redirectToCorrectStep } from '@/modules/wizard/utils/redirectToCorrectStep';
import { useEshopsStore } from '@/stores/eshops';
import { useWizardStore } from '@/stores/wizard';
import { useWizardHelperStore } from '@/stores/wizardHelper';
import { ClientEshopListEshopInfo, EnumsWizardStatus } from '../../../../generated/api';

const wizardStore = useWizardStore();
const wizardHelperStore = useWizardHelperStore();
const eshopsStore = useEshopsStore();
const router = useRouter();

defineProps({
  onWizard: {
    type: Boolean,
    default: false,
  },
});

const { selectedEshop, managedEshops } = storeToRefs(eshopsStore);

const onEshopChange = async (eshop: ClientEshopListEshopInfo) => {
  if (eshop) {
    const isSelected = await eshopsStore.setSelectedEshop(eshop);
    if (!isSelected) return;
    if (eshopsStore.getSelectedEshop?.wizardStatus !== EnumsWizardStatus.AddedPixel) {
      await redirectToCorrectStep(eshopsStore.getSelectedEshop?.wizardStatus as EnumsWizardStatus);
    }
  }
};

const redirectToWizard = () => {
  wizardStore.resetWizard();
  wizardHelperStore.resetWizardHelper();
  wizardHelperStore.isNewEshop = true;
  router.push({
    name: WIZARD_ROUTES.ADD_ESHOP_PAGE,
  });
};
</script>
