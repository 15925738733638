export enum FULL_PAGE_ROUTES {
    ADD_ESHOP_MANAGER_CALLBACK_PAGE = 'routesAddEshopManagerCallbackPage',
    IFRAME_LOGIN_PAGE = 'routesIframeLoginPage',
    RECHARGE_CREDIT_PAGE = 'routesFullPageRechargeCreditPage',
    PAYMENT_PAGE = 'routesFullPagePaymentPage',
    PAYMENT_GATEWAY_PAGE = 'routesFullPageGatewayPage',
    PAYMENT_BANK_TRANSFER_PAGE = 'routesFullPageBankTransferPage',
    PAYMENT_AUTOMATIC_RECHARGE_PAGE = 'routesFullPageAutomaticRechargePage',
    PAYMENT_HISTORY_PAGE = 'routesFullPagePaymentHistoryPage',
    BILLING_INFO_PAGE = 'routesFullPageBillingInfoPage',
}
