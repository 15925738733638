<template>
    <div class="flex flex-col p-1">
        <div class="m-auto w-full">
            <div ref="checkoutRef"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { loadStripe } from '@stripe/stripe-js/pure';
import { useToast } from 'primevue/usetoast';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { CREDIT_CHECKOUT_TYPES } from '@/modules/rechargeCredit/enums/CreditCheckoutTypes';
import { getStripeRedirectURL } from '@/modules/rechargeCredit/utils/getStripeRedirectURL';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';

const { t } = useI18n();
const toast = useToast();
const eshopsStore = useEshopsStore();
const loadingStore = useLoadingStore();

const checkoutRef = ref();
const stripeInstance = ref();
const checkout = ref();

onMounted(async () => {
    await initCheckout();
});

watch(
    () => eshopsStore.getSelectedEshop?.id,
    async () => {
        await initCheckout();
    }
);

const initCheckout = async () => {
    loadingStore.updateLoading(true);
    try {
        const { data } = await api.clientRechargeCreditCreateCheckout({
            callbackUrl: getStripeRedirectURL(CREDIT_CHECKOUT_TYPES.CHECKOUT),
        });

        if (data.clientSecret === '') {
            handleError();
            return;
        }

        // Init Stripe instance
        if (!stripeInstance.value) {
            stripeInstance.value = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY, {
                apiVersion: import.meta.env.VITE_STRIPE_API_VERSION,
            });
        }

        if (!stripeInstance.value) {
            handleError();
            return;
        }

        // Destroy old checkout
        if (checkout.value) {
            checkout.value.destroy();
        }

        checkout.value = await stripeInstance.value.initEmbeddedCheckout({
            clientSecret: data.clientSecret,
        });

        checkout.value.mount(checkoutRef.value);
    } catch (error) {
        handleError();
    } finally {
        loadingStore.updateLoading(false);
    }
};

const handleError = () => {
    toast.add({
        severity: 'error',
        summary: t('rechargeCredit.errorCheckoutTitle'),
        detail: '',
        life: 5000,
    });
};

onUnmounted(() => {
    if (checkout.value) {
        checkout.value.destroy();
    }
});
</script>
