<template>
  <div class="mb-2 flex justify-end">
    <div>
      <!--
      <div class="mb-2 block w-full min-w-fit border-none px-0 dark:bg-[#28334e] lg:w-72">
        <select v-model="selectDays" id="location" name="location" class="mx-auto mt-1 w-full min-w-fit rounded-full border-gray-300 lg:w-72">
          <option value="today">
            {{ $t('statistics.pages.statistics.today') }}
          </option>
          <option value="yesterday">
            {{ $t('statistics.pages.statistics.yesterday') }}
          </option>
          <option value="last7Days">
            {{ $t('statistics.pages.statistics.last7Days') }}
          </option>
          <option value="lastMonth">
            {{ $t('statistics.pages.statistics.lastMonth') }}
          </option>
          <option value="last3Month">
            {{ $t('statistics.pages.statistics.last3Month') }}
          </option>
          <option value="last6Month">
            {{ $t('statistics.pages.statistics.last6Month') }}
          </option>
          <option value="lastYear">
            {{ $t('statistics.pages.statistics.lastYear') }}
          </option>
        </select>
      </div>-->
      <Datepicker
        class="relative block w-full min-w-fit rounded-full border-none px-0 dark:bg-[#28334e] lg:w-72"
        :lang="userStore.languageCode"
        :locale="userStore.languageCode"
        :format="userStore.languageCode == 'cs' ? 'dd.MM.yyyy' : 'MM/dd/yyyy'"
        type="date"
        :selectText="$t('statistics.pages.statistics.select')"
        :cancelText="$t('statistics.pages.statistics.cancel')"
        :clearable="false"
        v-model="dates"
        :disabled-dates="disabledDates"
        :enable-time-picker="true"
        :auto-position="false"
        :min-date="new Date(2024, 7, 20)"
        range
        :teleport="true"
      />
      <span class="text-center text-xs text-main">{{ $t('statistics.pages.statistics.datesWarning') }}</span>
    </div>
  </div>

  <!-- VISIT SOURCE -->
  <div class="mb-4 flex w-fit items-center">
    <h1 class="text-base font-bold text-main sm:text-lg">
      {{ $t('statistics.pages.statistics.exportText') }}
    </h1>
    <StatisticsExport :dates="{ dateFrom: dates[0], dateTo: dates[1] }" statisticsType="visitSource" :showPDF="false" :filters="lazyParamsVisitSource" :totalRows="totalRecordsVisitSource" />
  </div>
  <DataTable
    :key="'visitSource'"
    v-model:filters="visitSourceFilters"
    :value="visitSource"
    filterDisplay="row"
    dataKey="id"
    showGridlines
    stripedRows
    size="small"
    paginator
    :rows="10"
    scrollable
    :rowsPerPageOptions="[5, 10, 20, 50, 100]"
    :loading="loading"
    :totalRecords="totalRecordsVisitSource"
    :lazy="true"
    @page="onPageVisitSource($event)"
    @sort="onSortVisitSource($event)"
    @filter="onFilterVisitSource($event)"
    ref="visitSourceTable"
    :first="first"
    :ptOptions="{ mergeProps: true }"
    :pt="{
      column: {
        headercell: ({ context, props }) => ({
          class: [''],
        }),
      },
    }"
  >
    <template #empty>
      <div class="my-auto">
        <h1 class="my-auto block text-center text-xl font-bold text-main">{{ $t('statistics.pages.statistics.dataNotFound') }}</h1>
      </div>
    </template>
    <Column sortable field="source" :header="$t('statistics.pages.statistics.trafficVisitSource')">
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          size="small"
          v-model="filterModel.value"
          type="text"
          @keydown.enter="filterCallback()"
          class="p-column-filter"
          :pt-options="{ mergeProps: true }"
          :pt="{
            root: ({ props, context }) => ({
              class: [
                'w-full',
                'm-0',
                'placeholder:!text-gray-500',
                '!rounded-md',
                {
                  'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
                },
                {
                  '!text-xs px-2 !py-1': props.size === 'small',
                  'p-1 sm:!p-1 !text-xs': props.size == null,
                },
              ],
            }),
          }"
        />
      </template>
    </Column>
    <Column sortable field="userCount" :header="$t('statistics.pages.statistics.trafficVisitUserCount')"></Column>
    <Column sortable field="orderCount" :header="$t('statistics.pages.statistics.trafficVisitOrderCount')"></Column>
    <Column sortable field="revenue" :header="$t('statistics.pages.statistics.trafficVisitRevenue')">
      <template #body="slotProps">
        <span v-if="slotProps.data.revenue === null" class="mx-auto text-center">-</span>
        <LocalizedValue v-else :value="slotProps.data.revenue"></LocalizedValue>
      </template>
    </Column>
    <Column sortable field="conversionRate" :header="$t('statistics.pages.statistics.trafficVisitConversionRate')">
      <template #body="slotProps">
        <span v-if="slotProps.data.conversionRate === null" class="mx-auto text-center">-</span>
        <LocalizedValue v-else :value="slotProps.data.conversionRate"></LocalizedValue>
      </template>
    </Column>
  </DataTable>
</template>
<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker';
import { FilterMatchMode } from 'primevue/api';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import StatisticsExport from '@/modules/statistics/components/StatisticsExport.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';
import { calculateDateRange } from '@/utils/statisticsCalculateDateRange';

const visitSourceTable = ref();
const lazyParamsVisitSource = ref({});

const loading = ref(false);
const totalRecordsVisitSource = ref(0);
const first = ref(0);
const userStore = useUserStore();
const eshopStore = useEshopsStore();
const selectDays = ref('today');
const disabledDates = ref([]);

const visitSource = ref();
const toast = useToast();
const today = new Date();

/*
LAST MONTH

const dates = ref([new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0, 0), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)]);
*/
/*
PRODUCTION
*/
const dates = ref([new Date(2024, 7, 20), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)]);
const visitSourceFilters = ref({
  source: {
    value: '',
    matchMode: FilterMatchMode.CONTAINS,
  },
});

onMounted(async () => {
  loading.value = true;

  lazyParamsVisitSource.value = {
    dateFrom: dates.value[0], // TO DO: SET THE PRODUCTION DATE
    dateTo: dates.value[1], // TO DO: SET TODAY DATE
    first: visitSourceTable.value.first,
    rows: visitSourceTable.value.rows,
    sortField: null,
    sortOrder: null,
    filters: visitSourceFilters.value,
    page: 0,
  };

  setDisableDates();
  await loadLazyVisitSourceData();
});

const loadLazyVisitSourceData = async (event?) => {
  loading.value = true;
  lazyParamsVisitSource.value = {
    ...lazyParamsVisitSource.value,
    first: event?.first || first.value,
    utcOffset: new Date().getTimezoneOffset() / -60,
  };
  lazyParamsVisitSource.value.dateFrom = dates.value[0];
  lazyParamsVisitSource.value.dateTo = dates.value[1];
  try {
    const { data } = await api.clientStatisticsGetVisitSourceStats(lazyParamsVisitSource.value);
    visitSource.value = data.stats;
    totalRecordsVisitSource.value = data.totalRows || 0;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loading.value = false;
  }
};

const onPageVisitSource = (event) => {
  lazyParamsVisitSource.value = { ...lazyParamsVisitSource.value, ...event };
  loadLazyVisitSourceData(event);
};

const onSortVisitSource = (event) => {
  lazyParamsVisitSource.value = { ...lazyParamsVisitSource.value, ...event };
  loadLazyVisitSourceData(event);
};

const onFilterVisitSource = (event) => {
  lazyParamsVisitSource.value.filters = visitSourceFilters.value;
  loadLazyVisitSourceData(event);
};

const setDisableDates = () => {
  const today = new Date();
  for (let i = 1; i < 32; i++) {
    disabledDates.value.push(new Date(new Date().setDate(today.getDate() + i)));
  }
};

watch(
  () => eshopStore.getSelectedEshop?.id,
  async () => {
    await loadLazyVisitSourceData();
  }
);

watch(
  () => dates.value,
  async (val) => {
    // watch the date
    if (dates.value && dates.value.length === 2) {
      await loadLazyVisitSourceData();
    }
  }
);

watch(
  () => selectDays.value,
  async (selectedValue) => {
    const { startDate, endDate } = calculateDateRange(selectedValue);

    dates.value = [startDate, endDate];
    await loadLazyVisitSourceData();
  }
);
</script>
