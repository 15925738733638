import { useJwt } from '@vueuse/integrations/useJwt';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import router from '@/router';
import Queue from '@/services/api/queue';
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';

class UnauthorizedError extends Error {
  name;
  response;
  constructor(message) {
    super(message);
    this.name = 'UnauthorizedError';
    this.response = {
      data: {
        detail: message,
      },
    };
  }
}

class ForbiddenError extends Error {
  name;
  response;

  constructor(message) {
    super(message);
    this.name = 'ForbiddenError';
    this.response = {
      data: {
        detail: message,
      },
    };
  }
}

const queue = new Queue(false);
let refreshing = false;

const checkRefreshToken = async () => {
  const authStore = useAuthStore();
  const refreshToken = authStore.getRefreshToken;

  if (!refreshToken) {
    return false;
  }

  const { payload } = useJwt(refreshToken);
  const refreshTokenExpiration = payload.value?.exp;
  return !(refreshTokenExpiration && Math.floor(Date.now() / 1000) >= refreshTokenExpiration);
};

const checkAndAddAccessTokenToHeader = async (config) => {
  const authStore = useAuthStore();

  const accessToken = config.metadata?.accessToken === 'user' ? authStore.getUserAccessToken : authStore.getEshopAccessToken;

  if (!accessToken) {
    return false;
  }

  const { payload } = useJwt(accessToken);
  const accessTokenExpiration = payload.value?.exp;
  // If the access token has not expired, return config
  if (accessTokenExpiration && Math.floor(Date.now() / 1000) < accessTokenExpiration) {
    // Add access token to the request config only if not already present
    if (!config.headers.Authorization) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return true;
  } else {
    return false;
  }
};

const refreshAccessToken = async (config) => {
  const authStore = useAuthStore();

  // If it has expired, and we are not updating it, start that now
  if (!refreshing) {
    refreshing = true;

    let newAccessToken = '';

    if (config.metadata?.accessToken === 'user') {
      newAccessToken = await authStore.refreshUserAccessToken();
    } else {
      newAccessToken = await authStore.refreshEshopAccessToken();
    }

    if (!newAccessToken) {
      return false;
    }

    // Add new access token to the request config
    config.headers.Authorization = `Bearer ${newAccessToken}`;

    // Set refreshing to false after successful token refresh
    refreshing = false;

    // Update the queue with the new token implement the method here
    queue.drain();

    return true;
  } else {
    // Some other call already triggered of the refresh token
    // so lets add this to the queue to be called once the
    // token is done refreshing
    await new Promise((resolve) => {
      queue.add(function () {
        const token = config.metadata?.accessToken === 'user' ? authStore.getUserAccessToken : authStore.getEshopAccessToken;

        config.headers.Authorization = `Bearer ${token}`;
        resolve(true);
      });
    });
    return true;
  }
};

const handleRefreshTokenError = async (abortController) => {
  await router.push({
    name: CLIENT_ROUTES.LOGOUT_PAGE,
  });
  abortController.abort();
  return Promise.reject(new Error('Refresh token expired'));
};

export const requestInterceptor = async (config) => {
  const userStore = useUserStore();
  config.headers['Accept-Language'] = userStore.getLanguageCode;

  const abortController = new AbortController();

  // Abort the request if timeout is reached
  setTimeout(() => {
    abortController.abort();
  }, 100000);

  config.signal = abortController.signal;

  // If the request is for public endpoint just return the config
  if (config.metadata?.requestType === RequestTypeEnum.PUBLIC || config.metadata?.requestType === RequestTypeEnum.REFRESH_TOKEN) {
    return config;
  }

  // Check if the refresh token is expired
  const refreshTokenValid = await checkRefreshToken();
  if (!refreshTokenValid) {
    await handleRefreshTokenError(abortController);
  }

  // Check if the access token is expired
  const accessTokenValid = await checkAndAddAccessTokenToHeader(config);
  if (!accessTokenValid) {
    // If the access token has expired, refresh it
    const refreshed = await refreshAccessToken(config);
    if (!refreshed) {
      await handleRefreshTokenError(abortController);
    }
  }

  return config;
};

export const errorInterceptor = async (error) => {
  if (error.response && error.response.status === 401) {
    try {
      await router.push({
        name: CLIENT_ROUTES.LOGOUT_PAGE,
      });
    } catch (e) {
      console.error(e);
    }
    throw new UnauthorizedError('User is not authorized. Redirected to logout.');
  } else if (error.response && error.response.status === 403) {
    throw new ForbiddenError('User permissions have changed. Redirected to dashboard.');
  } else {
    return Promise.reject(error);
  }
};
