<template>
    <div class="relative m-auto h-full w-full">
        <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="wizardLogo" class="absolute left-5 top-5 w-64 object-cover" />
        <div class="absolute right-5 top-5 w-fit object-cover">
            <router-link :to="{ name: CLIENT_ROUTES.LOGOUT_PAGE }" class="h-6 w-6 sm:h-10 sm:w-10 lg:h-12 lg:w-12">
                <svg xmlns="http://www.w3.org/2000/svg" class="my-auto h-6 w-6 font-extrabold text-main sm:h-8 sm:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
            </router-link>
        </div>
        <div class="absolute left-1/2 top-1/2 h-fit w-full -translate-x-1/2 -translate-y-1/2">
            <div class="mx-auto mb-10 max-w-2xl text-center">
                <h2 class="mt-28 text-base font-semibold leading-7 text-main sm:mt-10 lg:mt-0">
                    {{ $t('authentication.listOfEshops.welcome') }}
                </h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
                    {{ $t('authentication.listOfEshops.chooseEshop') }}
                </p>
            </div>
            <div class="m-auto max-h-96 overflow-y-scroll rounded-lg shadow-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-h-[30rem]">
                <ul role="list" class="m-auto h-full w-full divide-y divide-gray-100">
                    <li
                        @click="openEshop(eshop)"
                        v-for="(eshop, index) in eshopsStore.managedEshops"
                        :key="eshop.id"
                        :class="[
                            index % 2 === 0 ? 'bg-white hover:bg-gray-300 dark:bg-lightDark hover:dark:bg-mainDark' : 'bg-gray-100 hover:bg-gray-300 dark:bg-[#191f2e] hover:dark:bg-mainDark',
                            'relative w-full cursor-pointer items-center p-5 sm:flex',
                        ]"
                    >
                        <div class="mb-5 flex w-full justify-center gap-x-4 sm:mb-0 sm:w-auto sm:justify-start">
                            <img class="h-8 w-8 flex-none rounded-full bg-gray-50" :src="`${$filePath}/flags/${eshop.country}.png`" alt="" />
                            <div class="min-w-0">
                                <p class="font-semibold leading-6 text-gray-900 dark:text-gray-300">
                                    {{ eshop.domainName }}
                                </p>
                            </div>
                        </div>
                        <div class="mx-auto w-fit items-center gap-x-4 sm:flex sm:w-full sm:justify-end">
                            <div class="mr-2 flex flex-col items-end">
                                <span v-if="eshop.campaignsStatus && Number(eshop.credit) > 0" class="inline-flex items-center rounded-full bg-green-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-green-600/20 sm:text-sm">{{
                                    $t('authentication.listOfEshops.active')
                                }}</span>
                                <span
                                    v-else-if="eshop.wizardStatus !== EnumsWizardStatus.AddedPixel"
                                    class="inline-flex items-center rounded-full bg-orange-600/90 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-red-600/20 sm:text-sm"
                                    >{{ $t('authentication.listOfEshops.warning') }}</span
                                >
                                <span v-else class="inline-flex items-center rounded-full bg-red-600/90 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-red-600/20 sm:text-sm">{{ $t('authentication.listOfEshops.inactive') }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { redirectToCorrectStep } from '@/modules/wizard/utils/redirectToCorrectStep';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { useWizardHelperStore } from '@/stores/wizardHelper';
import { ClientEshopListEshopInfo, EnumsWizardStatus } from '../../../../generated/api';

const eshopsStore = useEshopsStore();
const loadingStore = useLoadingStore();
const router = useRouter();
const wizardHelperStore = useWizardHelperStore();

const openEshop = async (eshop: ClientEshopListEshopInfo) => {
    loadingStore.updateLoading(true);

    const isSelected = await eshopsStore.setSelectedEshop(eshop);

    wizardHelperStore.isNewEshop = false;

    loadingStore.updateLoading(false);

    if (isSelected) {
        if (eshop.wizardStatus === EnumsWizardStatus.AddedPixel) {
            await router.push({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
        } else {
            await redirectToCorrectStep(eshop.wizardStatus as EnumsWizardStatus);
        }
        return;
    }
};
</script>
