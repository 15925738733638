<template>
  <Menubar
    class="mx-auto bg-main md:w-fit"
    :model="menuItems"
    :pt-options="{ mergeProps: true }"
    :pt="{ button: '!text-main !bg-white', menu: '!gap-2', menubar: '!bg-white', root: '!bg-white', action: '!px-4 !py-2 sm:!justify-center sm:!mx-auto !w-fit !hover-none ', content: '!bg-white' }"
  >
    <template #item="{ item, props }">
      <router-link v-if="item.route" v-slot="{ href, navigate, isActive }" :to="{ name: item.route }" custom>
        <a
          :href="href"
          v-bind="props.action"
          @click="navigate"
          :class="[
            'ml-2 flex items-center rounded border-b-2 border-white text-main hover:border-main hover:!bg-main hover:!text-white ',
            {
              'rounded !border-main bg-main  text-white': isActive,
            },
          ]"
        >
          <component :is="item.icon" class="h-6 w-6"> </component>
          <span class="ml-2 h-full">{{ item.label }}</span>
        </a>
      </router-link>
    </template>
  </Menubar>
  <div class="mt-4 rounded-md bg-white p-5 dark:bg-mainDark">
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { UserGroupIcon, GlobeAltIcon } from '@heroicons/vue/24/outline';
import Menubar from 'primevue/menubar';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';

const { t } = useI18n();
const router = useRouter();

const menuItems = ref([
  {
    label: t('statistics.pages.statistics.trafficSubmenuTraffic'),
    icon: UserGroupIcon,
    route: CLIENT_ROUTES.TRAFFIC_STATISTICS_PAGE,
  },
  {
    label: t('statistics.pages.statistics.trafficSubmenuVisitSource'),
    icon: GlobeAltIcon,
    route: CLIENT_ROUTES.TRAFFIC_VISIT_SOURCES_STATISTICS_PAGE,
  },
]);
</script>
