<template>
  <ul role="list" class="my-1 grid grid-cols-1 gap-6 rounded-lg sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
    <li v-for="format in formats" :key="format.name" class="relative col-span-1 flex flex-col divide-gray-200 rounded-2xl bg-white text-center shadow-xl">
      <div class="h-12 rounded-t-2xl bg-white" v-if="format.problemDetail">
        <div class="h-fit w-full rounded-t-2xl bg-red-700 p-1">
          <p class="text-sm text-white">{{ format.problemDetail }}</p>
        </div>
      </div>
      <div v-else class="relative h-12 w-full rounded-t-2xl bg-white p-1">
        <p class="text-sm text-white opacity-0">a</p>
        <div class="absolute left-3 top-3" @click="showPreview(format)">
          <EyeIcon class="h-5 w-5 cursor-pointer text-main hover:opacity-50" aria-hidden="true" />
        </div>
      </div>
      <div class="flex flex-1 flex-col px-2 pb-1 pt-4">
        <img class="mx-auto h-20 w-20 shrink-0 rounded-full" :src="$filePath + format.imageUrl" alt="" />
        <h3 class="mt-3 text-lg font-bold text-[#F67424]">{{ format.name }}</h3>
      </div>
      <div class="mx-auto px-5 pb-4">
        <span v-if="format.budget?.dailyBudget && (format.budget?.dailyBudget !== format.budget?.minimalDailyBudget.value)" class="flex items-center text-sm text-gray-500">
          <LocalizedValue :value="{ value: format.budget.dailyBudget, currency: format.budget.minimalDailyBudget.currency, type: format.budget.minimalDailyBudget.type }"></LocalizedValue
          ><span class="ml-1">/ {{ $t('campaigns.budgetCampaignDay') }}</span>
          <PencilIcon @click="showDailyBudgetDialog(format)" class="ml-2 h-4 w-4 cursor-pointer text-[#F67424] hover:cursor-pointer hover:opacity-50" aria-hidden="true" />
        </span>
        <span v-else class="flex items-center">
          <span class="text-sm text-gray-500">{{ $t('campaigns.budgetAutomaticDayBudget') }}</span>
          <PencilIcon @click="showDailyBudgetDialog(format)" class="ml-2 h-4 w-4 cursor-pointer text-[#F67424] hover:cursor-pointer hover:opacity-50" aria-hidden="true" />
        </span>
      </div>
      <div>
        <div v-if="format.isCampaignExist" class="mx-auto flex w-full items-center justify-between">
          <div @click="showFormatEditByFormat(format)" class="flex w-fit cursor-pointer items-center gap-1 p-3 text-main hover:underline">
            <Cog8ToothIcon v-if="format.type !== EnumsMarketingFormat.Pla" class="h-5 w-5 cursor-pointer text-main" aria-hidden="true" />
            <span v-if="format.type !== EnumsMarketingFormat.Pla" class="text-main">{{ $t('campaigns.budgetEdit') }}</span>
          </div>
          <div class="p-3">
            <div v-if="!format.problemDetail" @click="tryChangeStatus(!format.formatStatus, format)">
              <InputSwitch v-bind:model-value="format.formatStatus" />
            </div>
            <div v-else @click="tryChangeStatus(!format.formatStatus, format)">
              <InputSwitch v-bind:model-value="format.formatStatus" :pt-options="{ mergeProps: true }" :pt="{ slider: '!bg-red-700 !before:transform !before:translate-x-5' }" />
            </div>
          </div>
        </div>
        <div v-else class="mx-auto flex w-full items-center justify-center">
          <div @click="goToWizard(format)" class="m-2.5 flex w-fit cursor-pointer items-center justify-center rounded-full border border-[#F67424] text-[#F67424] hover:bg-[#F67424] hover:text-white">
            <span class="px-2 py-1">{{ $t('campaigns.setCampaignAndOn') }}</span>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <DailyBudget v-model="showDailyBudget" :format="activeFormat" @updateDailyBudget="onUpdateFormats" />
  <PreviewDialog v-model="showPreviewModal" :format="activeFormat" />
  <EditDialog v-if="showFormatEdit" v-model="showFormatEdit" :campaign="props.campaign" :format="activeFormat" />
  <ReallyOffDialog v-model="visibleReallyOffDialog" @reallyOffDialogCallback="onReallyOff" />
</template>
<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { Cog8ToothIcon, EyeIcon, PencilIcon } from '@heroicons/vue/24/outline';
import InputSwitch from 'primevue/inputswitch';
import { useToast } from 'primevue/usetoast';
import { PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import DailyBudget from '@/modules/campaigns/components/DailyBudget.vue';
import EditDialog from '@/modules/campaigns/components/EditDialog.vue';
import PreviewDialog from '@/modules/campaigns/components/PreviewDialog.vue';
import ReallyOffDialog from '@/modules/campaigns/components/ReallyOffDialog.vue';
import { CampaignEnum } from '@/modules/campaigns/enums/CampaignEnum';
import { EditModeEnum } from '@/modules/campaigns/enums/EditModeEnum';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { IFormat } from '@/modules/campaigns/interfaces/IFormat';
import { translateCampaignStatusFromBooleanToEnum } from '@/modules/campaigns/utils/translateCampaignStatusFromBooleanToEnum';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import router from '@/router';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { EnumsMarketingFormat } from '../../../../generated/api';

const props = defineProps({
  campaign: {
    type: String as PropType<CampaignEnum>,
    required: true,
  },
  formats: {
    type: Array as PropType<IFormat[]>,
    required: false,
    default: () => [],
  },
});

const emit = defineEmits(['changeCampaignFormatStatus', 'updateFormats']);

const toast = useToast();
const loadingStore = useLoadingStore();
const { t } = useI18n();
const gtm = useGtm();
const eshopStore = useEshopsStore();

const showDailyBudget = ref(false);
const showFormatEdit = ref(false);
const showPreviewModal = ref(false);

const activeFormat = ref<IFormat>();

const visibleReallyOffDialog = ref(false);

const tryChangeStatus = async (status: boolean, format) => {
  if (status) {
    await changeStatus(status, format);
  } else {
    activeFormat.value = format;
    visibleReallyOffDialog.value = true;
  }
};

const onReallyOff = async (reallyOffDialogData) => {
  await changeStatus(false, activeFormat.value, reallyOffDialogData);
};

const changeStatus = async (status: boolean, format, reallyOffReason = {}) => {
  loadingStore.updateLoading(true);

  try {
    const payload = {
      desiredStatus: translateCampaignStatusFromBooleanToEnum(status),
    };

    const { data } = await api.clientCampaignsSetStatus(format.type, payload);

    const updatedFormat = {
      ...format,
      formatStatus: status,
      problemDetail: data.problemDetail,
    };

    emit('changeCampaignFormatStatus', updatedFormat);

    if (data.problemDetail === null) {
      if (!status) {
        await sendToGtmReasonWhyUserOffCampaign(format, reallyOffReason);
        toast.add({
          severity: 'success',
          summary: t('campaigns.campaignSuccessfullOff'),
          life: 5000,
        });
      } else {
        toast.add({
          severity: 'success',
          summary: t('campaigns.campaignSuccessfullOn'),
          life: 5000,
        });
      }
    }
  } catch (error: any) {
    const updatedFormat = {
      ...format,
      formatStatus: !status,
      problemDetail: null,
    };

    emit('changeCampaignFormatStatus', updatedFormat);
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const showDailyBudgetDialog = (format) => {
  if (format.formatStatus) {
    activeFormat.value = format;
    showDailyBudget.value = true;
  } else {
    toast.add({
      severity: 'error',
      summary: t('dailyBudget.campaignMustBeTurnedOn'),
      life: 6000,
    });
  }
};

const showFormatEditByFormat = async (format) => {
  if (format.type === EnumsMarketingFormat.Pla) {
    return;
  }
  if (format.type === EnumsMarketingFormat.Dsa) {
    await router.push({ name: CAMPAIGNS_ROUTES.EDIT_STEP, params: { campaign: props.campaign, format: format.type, step: 'descriptions' }, query: { editMode: EditModeEnum.EDIT } });
    return;
  }

  activeFormat.value = format;
  showFormatEdit.value = true;
};

const goToWizard = async (format) => {
  await router.push({
    name: CAMPAIGNS_ROUTES.EDIT,
    query: { editMode: EditModeEnum.WIZARD },
    params: {
      campaign: props.campaign,
      format: format.type,
    },
  });
};

const showPreview = async (format) => {
  activeFormat.value = format;
  showPreviewModal.value = true;
};

const sendToGtmReasonWhyUserOffCampaign = async (format, reallyOffReason) => {
  gtm?.trackEvent({
    event: 'gtm.reasonShutDownCampaign',
    type: format.type,
    value: {
      reason: reallyOffReason,
      eshopId: eshopStore.selectedEshop.id,
      domainName: eshopStore.selectedEshop.domainName,
    },
  });
  visibleReallyOffDialog.value = false;
};

const onUpdateFormats = (format) => {
  emit('updateFormats', format);
};
</script>
