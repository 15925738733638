<template>
  <div class="mb-5 flex flex-col justify-between sm:flex-row">
    <div>
      <span class="font-bold text-main">{{ $t('statistics.pages.statistics.averageOrderValue') }}</span
      ><span class="ml-2"><LocalizedValue :value="averageOrderPrice"></LocalizedValue></span>
    </div>
    <div class="mt-4 sm:mt-0">
      <div class="mb-2 block w-full min-w-fit border-none px-0 dark:bg-[#28334e] lg:w-72">
        <select v-model="selectDays" id="location" name="location" class="mx-auto mt-1 w-full min-w-fit rounded-full border-gray-300 lg:w-72">
          <option value="today">
            {{ $t('statistics.pages.statistics.today') }}
          </option>
          <option value="yesterday">
            {{ $t('statistics.pages.statistics.yesterday') }}
          </option>
          <option value="last7Days">
            {{ $t('statistics.pages.statistics.last7Days') }}
          </option>
          <option value="lastMonth">
            {{ $t('statistics.pages.statistics.lastMonth') }}
          </option>
          <option value="last3Month">
            {{ $t('statistics.pages.statistics.last3Month') }}
          </option>
          <option value="last6Month">
            {{ $t('statistics.pages.statistics.last6Month') }}
          </option>
          <option value="lastYear">
            {{ $t('statistics.pages.statistics.lastYear') }}
          </option>
        </select>
      </div>
      <Datepicker
        class="relative mb-5 block w-full min-w-fit rounded-full border-none px-0 dark:bg-[#28334e] lg:w-72"
        :lang="userStore.languageCode"
        :locale="userStore.languageCode"
        :format="userStore.languageCode == 'cs' ? 'dd.MM.yyyy' : 'MM/dd/yyyy'"
        type="date"
        :selectText="$t('statistics.pages.statistics.select')"
        :cancelText="$t('statistics.pages.statistics.cancel')"
        :clearable="false"
        v-model="dates"
        :disabled-dates="disabledDates"
        :enable-time-picker="true"
        :auto-position="false"
        :teleport="true"
        range
      >
      </Datepicker>
    </div>
  </div>
  <div class="mb-4 flex w-fit items-center">
    <h1 class="text-base font-bold text-main sm:text-lg">
      {{ $t('statistics.pages.statistics.exportText') }}
    </h1>
    <StatisticsExport :dates="{ dateFrom: dates[0], dateTo: dates[1] }" statisticsType="order" :showPDF="false" :filters="lazyParams" :totalRows="totalRecords" />
  </div>
  <DataTable
    v-model:filters="filters"
    v-model:expandedRows="expandedRows"
    :value="orders"
    filterDisplay="row"
    dataKey="id"
    showGridlines
    stripedRows
    size="small"
    paginator
    :rows="10"
    scrollable
    :rowsPerPageOptions="[5, 10, 20, 50, 100]"
    :loading="loading"
    :totalRecords="totalRecords"
    :lazy="true"
    @page="onPage($event)"
    @sort="onSort($event)"
    @filter="onFilter($event)"
    ref="dt"
    :first="first"
    :ptOptions="{ mergeProps: true }"
    :pt="{
      column: {
        headercell: ({ context, props }) => ({
          class: [''],
        }),
      },
    }"
  >
    <template #empty>
      <div class="my-auto">
        <h1 class="my-auto block text-center text-xl font-bold text-main">{{ $t('statistics.pages.statistics.ordersNotFound') }}</h1>
      </div>
    </template>
    <Column expander style="width: 3rem" />
    <Column field="transactionId" :header="$t('statistics.pages.statistics.orderTransactionId')"></Column>
    <Column sortable field="timestamp" :header="$t('statistics.pages.statistics.orderTime')">
      <template #body="slotProps">
        <LocalizedValue :value="{ value: slotProps.data.timestamp, type: 'Date', format: 'long' }"></LocalizedValue>
      </template>
    </Column>
    <Column :showFilterMenu="false" :showFilterOperator="false" sortable field="gmv" :header="$t('statistics.pages.statistics.orderGMV')">
      <template #body="slotProps">
        <LocalizedValue :value="slotProps.data.gmv"></LocalizedValue>
      </template>
    </Column>
    <Column :showFilterMenu="false" :showFilterOperator="false" sortable field="source" :header="$t('statistics.pages.statistics.orderSource')">
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          size="small"
          v-model="filterModel.value"
          type="text"
          @keydown.enter="filterCallback()"
          class="p-column-filter"
          :pt-options="{ mergeProps: true }"
          :pt="{
            root: ({ props, context }) => ({
              class: [
                'w-full',
                'm-0',
                'placeholder:!text-gray-500',
                '!rounded-md',
                {
                  'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
                },
                {
                  '!text-xs px-2 !py-1': props.size === 'small',
                  'p-1 sm:!p-1 !text-xs': props.size == null,
                },
              ],
            }),
          }"
        />
      </template>
    </Column>
    <Column :showFilterMenu="false" :showFilterOperator="false" sortable field="medium" :header="$t('statistics.pages.statistics.orderMedium')">
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          size="small"
          v-model="filterModel.value"
          type="text"
          @keydown.enter="filterCallback()"
          class="p-column-filter"
          :pt-options="{ mergeProps: true }"
          :pt="{
            root: ({ props, context }) => ({
              class: [
                'w-full',
                'm-0',
                'placeholder:!text-gray-500',
                '!rounded-md',
                {
                  'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
                },
                {
                  '!text-xs px-2 !py-1': props.size === 'small',
                  'p-1 sm:!p-1 !text-xs': props.size == null,
                },
              ],
            }),
          }"
        />
      </template>
    </Column>
    <Column :showFilterMenu="false" :showFilterOperator="false" sortable field="campaign" :header="$t('statistics.pages.statistics.orderCampaign')">
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          size="small"
          v-model="filterModel.value"
          type="text"
          @keydown.enter="filterCallback()"
          class="p-column-filter"
          :pt-options="{ mergeProps: true }"
          :pt="{
            root: ({ props, context }) => ({
              class: [
                'w-full',
                'm-0',
                'placeholder:!text-gray-500',
                '!rounded-md',
                {
                  'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
                },
                {
                  '!text-xs px-2 !py-1': props.size === 'small',
                  'p-1 sm:!p-1 !text-xs': props.size == null,
                },
              ],
            }),
          }"
        />
      </template>
    </Column>
    <Column :showFilterMenu="false" :showFilterOperator="false" field="orderStatType" header="">
      <template #body="slotProps">
        <span v-if="slotProps.data.orderStatType === EnumsOrderStatType.Assisted" class="whitespace-nowrap py-4 text-sm text-green-600 dark:text-[#cbd5e1]">
          <span
            v-tooltip.left="{
              value: `${$t('statistics.pages.statistics.orderAssistedTrue')}`,
              class: ' text-center',
              escape: false,
            }"
            class="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10"
          >
            {{ $t('statistics.pages.statistics.ordersAssisted') }}
          </span>
        </span>
        <span v-else-if="slotProps.data.orderStatType === EnumsOrderStatType.Tanganica" class="whitespace-nowrap py-4 text-sm text-main dark:text-[#cbd5e1]">
          <span class="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-main ring-1 ring-inset ring-blue-600/10"> Tanganica </span>
        </span>
        <span v-else-if="slotProps.data.orderStatType === EnumsOrderStatType.Other" class="whitespace-nowrap py-4 text-sm text-gray-500 dark:text-[#cbd5e1]">
          <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/10"> {{ $t('statistics.pages.statistics.orderOther') }} </span>
        </span>
        <span v-else-if="slotProps.data.orderStatType === EnumsOrderStatType.All" class="whitespace-nowrap py-4 text-sm text-black dark:text-[#cbd5e1]">
          <span class="inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-900/10"> {{ $t('statistics.pages.statistics.orderAll') }} </span>
        </span>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown
          v-model="filterModel.value"
          @change="filterCallback()"
          optionValue="value"
          optionLabel="label"
          :options="orderStatType"
          class="p-column-filter"
          style="min-width: 12rem"
          :showClear="false"
          :pt-options="{ mergeProps: true }"
          :pt="{
            input: () => ({
              class: ['sm:!p-1 !text-xs'],
            }),
          }"
        >
          <template #option="slotProps">
            <span v-if="slotProps.option.value === EnumsOrderStatType.Assisted" class="cursor-pointer whitespace-nowrap py-4 text-sm text-green-600 dark:text-[#cbd5e1]">
              <span class="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10"> {{ $t('statistics.pages.statistics.ordersAssisted') }} </span>
            </span>
            <span v-else-if="slotProps.option.value === EnumsOrderStatType.Tanganica" class="cursor-pointer whitespace-nowrap py-4 text-sm text-main dark:text-[#cbd5e1]">
              <span class="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-main ring-1 ring-inset ring-blue-600/10"> Tanganica </span>
            </span>
            <span v-else-if="slotProps.option.value === EnumsOrderStatType.Other" class="cursor-pointer whitespace-nowrap py-4 text-sm text-gray-500 dark:text-[#cbd5e1]">
              <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/10"> {{ $t('statistics.pages.statistics.orderOther') }} </span>
            </span>
            <span v-else-if="slotProps.option.value === EnumsOrderStatType.All" class="cursor-pointer whitespace-nowrap py-4 text-sm text-black dark:text-[#cbd5e1]">
              <span class="inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-900/10"> {{ $t('statistics.pages.statistics.orderAll') }} </span>
            </span>
          </template>
        </Dropdown>
      </template>
    </Column>

    <template #expansion="rootTableData">
      <div>
        <h5 class="mb-1 font-bold text-main">{{ $t('statistics.pages.statistics.orderProductDetail') }}</h5>
      </div>
      <DataTable
        :value="rootTableData.data.orderProducts"
        stripedRows
        showGridlines
        size="small"
        :ptOptions="{ mergeProps: true }"
        :pt="{
          column: {
            headercell: ({ context, props }) => ({
              class: ['!bg-main text-white'],
            }),
          },
        }"
      >
        <Column field="feedId" :header="$t('statistics.pages.statistics.orderProductId')">
          <template #body="slotProps">
            <span class="font-bold">{{ slotProps.data.feedId }}</span>
          </template>
        </Column>

        <Column field="name" :header="$t('statistics.pages.statistics.orderProductName')"> </Column>
        <Column field="quantity" :header="$t('statistics.pages.statistics.orderProductQuantity')"> </Column>
        <Column field="price" :header="$t('statistics.pages.statistics.orderProductPrice')">
          <template #body="slotProps">
            <span v-if="slotProps.data.price === null" class="mx-auto text-center">-</span>
            <LocalizedValue v-else :value="slotProps.data.price"></LocalizedValue>
          </template>
        </Column>
      </DataTable>
    </template>
  </DataTable>
</template>
<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker';
import { FilterMatchMode } from 'primevue/api';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import StatisticsExport from '@/modules/statistics/components/StatisticsExport.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';
import { calculateDateRange } from '@/utils/statisticsCalculateDateRange';
import { EnumsOrderStatType } from '../../../../generated/api';

const dt = ref();
const lazyParams = ref({});
const loading = ref(false);
const totalRecords = ref(0);
const first = ref(0);
const expandedRows = ref();
const userStore = useUserStore();
const eshopStore = useEshopsStore();
const selectDays = ref('lastMonth');
const disabledDates = ref([]);
const averageOrderPrice = ref();

const orders = ref();

const toast = useToast();
const today = new Date();
const dates = ref([new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0, 0), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)]);

const filters = ref({
  orderStatType: {
    value: 'All',
    matchMode: FilterMatchMode.EQUALS,
  },
  source: {
    value: null,
    matchMode: FilterMatchMode.CONTAINS,
  },
  medium: {
    value: null,
    matchMode: FilterMatchMode.CONTAINS,
  },
  campaign: {
    value: null,
    matchMode: FilterMatchMode.CONTAINS,
  },
});

const orderStatType = [
  { label: 'Assisted', value: EnumsOrderStatType.Assisted },
  { label: 'Tanganica', value: EnumsOrderStatType.Tanganica },
  { label: 'Other', value: EnumsOrderStatType.Other },
  { label: 'All', value: EnumsOrderStatType.All },
];

onMounted(async () => {
  loading.value = true;

  lazyParams.value = {
    dateFrom: dates.value[0],
    dateTo: dates.value[1],
    show: 'all', // 'all', 'active', 'newRegister', 'archive
    first: dt.value.first,
    rows: dt.value.rows,
    sortField: null,
    sortOrder: null,
    filters: filters.value,
    page: 0,
  };

  setDisableDates();
  loadLazyData();
});

const loadLazyData = async (event?) => {
  loading.value = true;
  lazyParams.value = {
    ...lazyParams.value,
    first: event?.first || first.value,
    utcOffset: new Date().getTimezoneOffset() / -60,
  };
  lazyParams.value.dateFrom = dates.value[0];
  lazyParams.value.dateTo = dates.value[1];
  try {
    const { data } = await api.clientStatisticsGetOrderStats(lazyParams.value);
    orders.value = data.orderStats;
    totalRecords.value = data.totalRows;
    averageOrderPrice.value = data.averageOrderGmv;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loading.value = false;
  }
};

const onPage = (event) => {
  expandedRows.value = null;
  lazyParams.value = { ...lazyParams.value, ...event };
  loadLazyData(event);
};
const onSort = (event) => {
  lazyParams.value = { ...lazyParams.value, ...event };
  loadLazyData(event);
};
const onFilter = (event) => {
  lazyParams.value.filters = filters.value;
  loadLazyData(event);
};

const setDisableDates = () => {
  const today = new Date();
  for (let i = 1; i < 32; i++) {
    disabledDates.value.push(new Date(new Date().setDate(today.getDate() + i)));
  }
};

watch(
  () => eshopStore.getSelectedEshop?.id,
  async () => {
    expandedRows.value = null;
    await loadLazyData();
  }
);

watch(
  () => dates.value,
  async (val) => {
    // watch the date
    if (dates.value && dates.value.length === 2) {
      expandedRows.value = null;
      await loadLazyData();
    }
  }
);

watch(
  () => selectDays.value,
  async (selectedValue) => {
    const { startDate, endDate } = calculateDateRange(selectedValue);

    dates.value = [startDate, endDate];
    expandedRows.value = null;
    await loadLazyData();
  }
);
</script>
