<template>
    <!-- Desktop Component -->
    <section aria-labelledby="profile-overview-titl" class="hidden w-full items-center lg:sticky lg:top-0 lg:z-50 lg:block">
        <div class="rounded-3xl rounded-b-none border-b border-gray-300 bg-gray-100 py-8 dark:bg-[#232d45]">
            <div class="bg-gray-100 dark:bg-[#232d45]">
                <div class="mx-auto flex items-center justify-between">
                    <div class="">
                        <div class="ml-10 bg-gray-100 dark:bg-[#232d45] dark:text-[#cbd5e1]">
                            <div class="flex items-center border-gray-300 text-left text-xs font-medium xl:text-sm">
                                <span class="text-xs text-gray-900 dark:text-[#cbd5e1] xl:text-sm">{{ $t('layout.client.topMenu.credit') }} </span>
                                <span @click="redirectToCredit" :class="[!isCreditUnderLimit ? 'text-red-600 dark:text-red-600' : 'text-gray-600 dark:text-[#cbd5e1]', 'w-fit cursor-pointer pl-2 hover:underline']">
                                    <LocalizedValue v-if="credit" :value="credit"></LocalizedValue>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mx-auto text-center lg:flex">
                        <SelectEshop as="div" />
                    </div>
                    <div class="relative z-[1000] ml-auto mr-10 h-auto w-10 text-left">
                        <UserMenu></UserMenu>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Mobile Component -->
    <section aria-labelledby="profile-overview-title" class="lg:hidden">
        <div class="overflow-hidden rounded-lg bg-gray-100 dark:bg-[#232d45]">
            <div class="bg-gray-100 pt-2 dark:bg-[#232d45]">
                <div class="mx-auto items-center justify-between">
                    <div class=""></div>
                    <SelectEshop as="div" />
                </div>
            </div>
            <div class="mt-12 border-b border-gray-300 bg-gray-100 dark:bg-[#232d45] dark:text-[#cbd5e1] lg:mt-0">
                <div class="flex w-full items-center border-gray-300 py-2 pl-8 text-center text-sm font-medium">
                    <span class="mr-1 block text-xs text-gray-900 dark:text-[#cbd5e1]">{{ $t('layout.client.topMenu.credit') }} </span>
                    <span @click="redirectToCredit" :class="[!isCreditUnderLimit ? 'text-red-600 dark:text-red-600' : 'text-gray-600 dark:text-[#cbd5e1]', 'w-fit cursor-pointer']">
                        <LocalizedValue v-if="credit" :value="credit"></LocalizedValue>
                    </span>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
import { useBroadcastChannel } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import UserMenu from '@/layout/components/UserMenu.vue';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import SelectEshop from '@/modules/global/components/SelectEshop.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { EnumsWizardStatus } from '../../../generated/api';
import { useUserStore } from '@/stores/user';
import { ThemeEnum } from '@/enums/ThemeEnum';
import {useGtm} from "@gtm-support/vue-gtm";

onMounted(async () => {
    await getCredit();
});

const { t } = useI18n();
const toast = useToast();
const credit = ref();
const limit = ref(0);
const eshopStore = useEshopsStore();
const userStore = useUserStore();
const router = useRouter();
const { data, close } = useBroadcastChannel({ name: 'credit' });

const isCreditUnderLimit = computed(() => {
    return limit.value < credit.value;
});

watch(
    () => eshopStore.getSelectedEshop?.id,
    async () => {
        await getCredit();
    }
);

watch(
    () => data.value,
    async (newVal) => {
        if (newVal) {
            if (eshopStore.getSelectedEshop?.wizardStatus === EnumsWizardStatus.AddedPixel) {
                await getCredit();
            }
        }
    }
);

const getCredit = async () => {
    try {
        const { data } = await api.clientTopMenuGetCredit();
        credit.value = data.credit;
        const { data: limitData } = await api.clientEshopSettingsGetCreditNotificationLimit();
        limit.value = limitData.creditLimit.value || 0;
    } catch (error: any) {
        if (error.response) {
            if (error.response.data.status >= 500) {
                toast.add({
                    severity: 'error',
                    summary: t('serverErrorTitle'),
                    detail: error.response.data.requestId,
                    life: 20000,
                });
            } else {
                toast.add({
                    severity: 'error',
                    summary: error.response.data.detail,
                    life: 6000,
                });
            }
        } else {
            console.warn(error);
        }
    }
};

const redirectToCredit = () => {
    // Iframe hacks
    if (userStore.getTheme === ThemeEnum.DEFAULT) {
        router.push({ name: CLIENT_ROUTES.RECHARGE_CREDIT_PAGE });
    }
};

onUnmounted(() => {
    close();
});
</script>
