<template>
  <component v-if="stepComponent" :is="stepComponent" :editMode="props.editMode" />
</template>

<script setup lang="ts">
import { PropType, shallowRef, watch } from 'vue';
import { CampaignEnum } from '@/modules/campaigns/enums/CampaignEnum';
import { EditModeEnum } from '@/modules/campaigns/enums/EditModeEnum';
import { EnumsMarketingFormat } from '../../../../generated/api';

const props = defineProps({
  campaign: {
    type: String as PropType<CampaignEnum>,
    required: true,
  },
  format: {
    type: String as PropType<EnumsMarketingFormat>,
    required: true,
  },
  step: {
    type: String,
    required: true,
  },
  editMode: {
    type: String as PropType<EditModeEnum>,
    required: false,
    default: EditModeEnum.WIZARD,
  },
});

const stepComponent = shallowRef<any>(null);

const loadComponent = async () => {
  try {
    const module = await import(`@/modules/campaigns/pages/${props.campaign}/${props.format?.toUpperCase()}/${props.step?.charAt(0).toUpperCase() + props.step?.slice(1)}.vue`);
    stepComponent.value = module.default || module;
  } catch (error) {
    console.warn('Failed to load step component:', error);
  }
};

watch(() => props.step, loadComponent, { immediate: true });
</script>
