<template>
  <div class="mb-2 flex justify-end">
    <div>
      <!-- <div class="mb-2 block w-full min-w-fit border-none px-0 dark:bg-[#28334e] lg:w-72">
        <select v-model="selectDays" id="location" name="location" class="mx-auto mt-1 w-full min-w-fit rounded-full border-gray-300 lg:w-72">
          <option value="today">
            {{ $t('statistics.pages.statistics.today') }}
          </option>
          <option value="yesterday">
            {{ $t('statistics.pages.statistics.yesterday') }}
          </option>
          <option value="last7Days">
            {{ $t('statistics.pages.statistics.last7Days') }}
          </option>
          <option value="lastMonth">
            {{ $t('statistics.pages.statistics.lastMonth') }}
          </option>
          <option value="last3Month">
            {{ $t('statistics.pages.statistics.last3Month') }}
          </option>
          <option value="last6Month">
            {{ $t('statistics.pages.statistics.last6Month') }}
          </option>
          <option value="lastYear">
            {{ $t('statistics.pages.statistics.lastYear') }}
          </option>
        </select>
      </div>-->
      <Datepicker
        class="relative block w-full min-w-fit rounded-full border-none px-0 dark:bg-[#28334e] lg:w-72"
        :lang="userStore.languageCode"
        :locale="userStore.languageCode"
        :format="userStore.languageCode == 'cs' ? 'dd.MM.yyyy' : 'MM/dd/yyyy'"
        type="date"
        :selectText="$t('statistics.pages.statistics.select')"
        :cancelText="$t('statistics.pages.statistics.cancel')"
        :clearable="false"
        v-model="dates"
        :disabled-dates="disabledDates"
        :enable-time-picker="true"
        :auto-position="false"
        :min-date="new Date(2024, 7, 20)"
        range
        :teleport="true"
      >
      </Datepicker>
      <span class="text-center text-xs text-main">{{ $t('statistics.pages.statistics.datesWarning') }}</span>
    </div>
  </div>

  <!-- TRAFFIC -->
  <div class="mb-4 flex w-fit items-center">
    <h1 class="text-base font-bold text-main sm:text-lg">
      {{ $t('statistics.pages.statistics.exportText') }}
    </h1>
    <StatisticsExport :dates="{ dateFrom: dates[0], dateTo: dates[1] }" statisticsType="traffic" :showPDF="false" :filters="lazyParamsTrafficStats" :totalRows="totalRecordsTraffic" />
  </div>
  <DataTable
    :key="'traffic'"
    v-model:filters="trafficStatsFilters"
    :value="traffic"
    filterDisplay="row"
    dataKey="id"
    showGridlines
    stripedRows
    size="small"
    paginator
    :rows="10"
    scrollable
    :rowsPerPageOptions="[5, 10, 20, 50, 100]"
    :loading="loading"
    :totalRecords="totalRecordsTraffic"
    :lazy="true"
    @page="onPageTraffic($event)"
    @sort="onSortTraffic($event)"
    @filter="onFilterTraffic($event)"
    ref="trafficStatsTable"
    :first="first"
    :ptOptions="{ mergeProps: true }"
    :pt="{
      column: {
        headercell: ({ context, props }) => ({
          class: [''],
        }),
      },
    }"
  >
    <template #empty>
      <div class="my-auto">
        <h1 class="my-auto block text-center text-xl font-bold text-main">{{ $t('statistics.pages.statistics.dataNotFound') }}</h1>
      </div>
    </template>
    <Column sortable field="date" :header="$t('statistics.pages.statistics.trafficDate')"></Column>
    <Column sortable field="userCount" :header="$t('statistics.pages.statistics.trafficUserCount')"></Column>
    <Column sortable field="orderCount" :header="$t('statistics.pages.statistics.trafficOrderCount')"></Column>
    <Column sortable field="revenue" :header="$t('statistics.pages.statistics.trafficRevenue')">
      <template #body="slotProps">
        <span v-if="slotProps.data.revenue === null" class="mx-auto text-center">-</span>
        <LocalizedValue v-else :value="slotProps.data.revenue"></LocalizedValue>
      </template>
    </Column>
    <Column sortable field="conversionRate" :header="$t('statistics.pages.statistics.trafficConversionRate')">
      <template #body="slotProps">
        <span v-if="slotProps.data.conversionRate === null" class="mx-auto text-center">-</span>
        <LocalizedValue v-else :value="slotProps.data.conversionRate"></LocalizedValue>
      </template>
    </Column>
  </DataTable>
</template>
<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import StatisticsExport from '@/modules/statistics/components/StatisticsExport.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';
import { calculateDateRange } from '@/utils/statisticsCalculateDateRange';

const trafficStatsTable = ref();
const lazyParamsTrafficStats = ref({});

const loading = ref(false);
const totalRecordsTraffic = ref(0);
const first = ref(0);
const userStore = useUserStore();
const eshopStore = useEshopsStore();
const selectDays = ref('lastMonth');
const disabledDates = ref([]);

const traffic = ref();
const toast = useToast();
const today = new Date();
/*
LAST MONTH
const dates = ref([new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0, 0), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)]);
*/
/*
PRODUCTION
*/
const dates = ref([new Date(2024, 7, 20), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)]);

const trafficStatsFilters = ref({});

onMounted(async () => {
  loading.value = true;

  lazyParamsTrafficStats.value = {
    dateFrom: dates.value[0], // TO DO: SET THE PRODUCTION DATE
    dateTo: dates.value[1], // TO DO: SET TODAY DATE
    show: 'all', // 'all', 'active', 'newRegister', 'archive
    first: trafficStatsTable.value.first,
    rows: trafficStatsTable.value.rows,
    sortField: null,
    sortOrder: null,
    filters: trafficStatsFilters.value,
    page: 0,
  };

  setDisableDates();
  await loadLazyTrafficData();
});

const loadLazyTrafficData = async (event?) => {
  loading.value = true;
  lazyParamsTrafficStats.value = {
    ...lazyParamsTrafficStats.value,
    first: event?.first || first.value,
    utcOffset: new Date().getTimezoneOffset() / -60,
  };
  lazyParamsTrafficStats.value.dateFrom = dates.value[0];
  lazyParamsTrafficStats.value.dateTo = dates.value[1];
  try {
    const { data } = await api.clientStatisticsGetTrafficStats(lazyParamsTrafficStats.value);
    traffic.value = data.stats;
    totalRecordsTraffic.value = data.totalRows || 0;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loading.value = false;
  }
};

const onPageTraffic = (event) => {
  lazyParamsTrafficStats.value = { ...lazyParamsTrafficStats.value, ...event };
  loadLazyTrafficData(event);
};

const onSortTraffic = (event) => {
  lazyParamsTrafficStats.value = { ...lazyParamsTrafficStats.value, ...event };
  loadLazyTrafficData(event);
};

const onFilterTraffic = (event) => {
  lazyParamsTrafficStats.value.filters = trafficStatsFilters.value;
  loadLazyTrafficData(event);
};

const setDisableDates = () => {
  const today = new Date();
  for (let i = 1; i < 32; i++) {
    disabledDates.value.push(new Date(new Date().setDate(today.getDate() + i)));
  }
};

watch(
  () => eshopStore.getSelectedEshop?.id,
  async () => {
    await loadLazyTrafficData();
  }
);

watch(
  () => dates.value,
  async (val) => {
    // watch the date
    if (dates.value && dates.value.length === 2) {
      await loadLazyTrafficData();
    }
  }
);

watch(
  () => selectDays.value,
  async (selectedValue) => {
    const { startDate, endDate } = calculateDateRange(selectedValue);

    dates.value = [startDate, endDate];
    await loadLazyTrafficData();
  }
);
</script>
