<template>
    <main class="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div class="text-center">
            <p class="mt-6 p-20 text-justify text-lg font-bold leading-7 text-main lg:text-xl">
                {{ quotes[randomQuoteNumber] }}
            </p>
        </div>
    </main>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { api } from '@/services/api';
import { useAuthStore } from '@/stores/auth';
import { useEshopsStore } from '@/stores/eshops';
import { sleep } from '@/utils/sleep';

const eshopsStore = useEshopsStore();
const router = useRouter();
const route = useRoute();

const userAccessToken = route.query.useraccesstoken as string;
const userRefreshToken = route.query.userrefreshtoken as string;
const eshopAccessToken = route.query.eshopaccesstoken as string;

const authStore = useAuthStore();

onMounted(async () => {
    await sleep(5000);
    await setTokens();
});

const setTokens = async () => {
    authStore.destroySession();
    if (userAccessToken && userRefreshToken && eshopAccessToken) {
        // Set user token
        authStore.setUserAccessToken(userAccessToken);

        // Set refresh token
        authStore.setRefreshToken(userRefreshToken);

        // Set eshop token
        authStore.setEshopAccessToken(eshopAccessToken);

        // Get eshop info
        const { data } = await api.clientEshopListGetEshop();

        eshopsStore.updateEshopAfterLoginFromAdmin(data.eshop);

        // Redirect to dashboard
        await router.push({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
    } else {
        await router.push({ name: CLIENT_ROUTES.LOGOUT_PAGE });
    }
};

const quotes = [
    '„Kuráž je dobrá. Vytrvalost lepší.“ – Jean de la Fontaine',
    '„Není nic moudřejšího než přesně vědět, kdy máš co začít a kdy s čím přestat.“ – Francis Bacon',
    '„Existují tisíce způsobů, jak zabít čas, ale žádný, jak ho vzkřísit.“ – Albert Einstein',
    '„Kapka, padá-li stále, ne silou vyhloubí kámen; člověk jen stálou pílí, ne naráz, se učeným stane.“ – latinské přísloví',
    '„Pokud důvěřuješ sám sobě a věříš svým snům a následuješ svou hvězdu, budeš stejně poražen lidmi, kteří trávili čas tvrdou prací a učením se a nebyli tak líní.“ – Terry Pratchett',
    '„Vybírat si a dělat tak méně, to je cesta k produktivitě. Zaměřit se na pár důležitých věcí a zbytek ignorovat.“ – Timothy Ferriss',
    '„Kdybychom měli měřit náš život na produktivitu, nebyl by náš život životem, ale pouhopouhým produktem.“ – Adam Komůrka',
    '„Pokud jsi schopný vzít si k srdci dvě prostá slova: vytrvej a odolej – pak můžeš žít nekonečně pokojný a ničím nerušený život.“ – Epiktétos',
    '„Úspěch je dítětem dvou rodičů: vytrvalosti a přesnosti.“ – Orison Swett Marden',
    '„Umění odpočinku je součástí umění práce.“ – John Steinbeck',
    '„Tajemství, jak se posouvat dál, je začít.“ – Mark Twain',
    '„Úspěch není výsledkem samovznícení. Zapálit se musíte sami.“ – Arnold H. Glasow',
    '„Muž, který hýbe horou, začíná přenášením kamínků.“ – Konfucius',
    '„Slunce vychází jen pro ty, kteří ráno vstávají.“ – Pavel Kosorin',
    '„Je lépe začít něco s pochybnostmi a skončit s jistotou než opačně.“ – O. F. Babler',
    '„Ten, kdo dokáže udělat krok do neznáma, začne mít život pod kontrolou.“ – Zuzana Klingrová',
    '„Udělat věc, které se bojíme, je první krok k úspěchu.” – Mahátma Gándhí',
    '„Ráno je u vody všechno hezké, protože den ještě nevzešel a my jsme si ho ještě nestačili otrávit.“ – Ota Pavel',
    '„Ke všemu, co je veliké, je prvým krokem odvaha.“ – J. W. von Goethe',
    '„Vírou uděláte první krok, přestože nevidíte celé schodiště.“ – Martin Luther King',
    '„Jestliže se člověk hádá s blbcem víc jak půl minuty, hádají se už dva blbci.“',
    '„Šly kolem dvě děti a chtěly pochovat, tak jsem je pochoval," řekl hrobař.“',
    '„Holčička cucá žiletku a směje se čím dál víc...“',
    '„Černej humor je jako nohy - někdo je má a někdo prostě nemá.“',
    '„Synek utíká a křičí: "Tatííí, tatínku, našel jsem babičku!" - "Kdo ti dovolil kopat na zahradě?!"“',
    '„Víte, jak se pozná židovský basketbalista? Čouhají mu nohy z pece.“',
    '„Víte jak velké postele mají katoličtí kněží? Ale no tááák, vždyť to není těžké... každé malé dítě to ví.“',
    '„Víte jaký je rozdíl mezi krajením cibule a smrtí koně? Žádný... brečíte u toho, ale do guláše to prostě patří.“',
    '„Co má společného alkoholik a nekrofilní pedofil? Oba ocení vychlazenou dvanáctku.“',
    '„Stín tichého oceánu“ - Robert Tichý',
    '„Luštit z kouře“ == Vymýšlet si',
    '„Ztratit se v židli“ == Nejsem tady',
    '„Řekl a odešel“ - Není co dodat',
    '„Odešli ven, třeba na týden“ == hlavní argument špatných výsledků',
    '„Účet se ztratí v čase“ - dodatek pro klienta, když už nechce využívat Tanganicu',
    '„Chceš pracovat v Tanganice?“ - musíš vyhulit tenhle kotel z bongu. A bez zakašlání',
    '„Si děláš prdel ne? Tebe bude krytka na blinkr stát litra? Ty vole já za litra udělám celý auto“ -  Jára Kesler, 2022',
    '„Kdybych pořád říkal komplimenty tak by to znělo neupřímně, někdy musíš někoho zroastit.“ -  Samuel Machat, 2022',
    '„Naši databázi by měla zavřít hygiena.“ -  David Holas, 2022',
    '„Když umřeš, tak seš NULL.“ -  David Holas, 2022',
    '„Já tak nesnášim kódování.“ -  David Holas, 2022',
    '„Výjimečné věci vznikají mimo komfortní zónu.“',
    '„Pokud čekáš na ten správný okamžik, můžeš čekat věčně.“',
    '„Práce s vášní je tisíckrát výkonnější než práce z povinnosti.“',
    '„Neměř svůj pokrok podle toho, jak daleko ještě musíš jít, ale jak daleko jsi už došel.“',
    '„Nepočítej dny, udělej, aby se dny počítaly.“',
    '„Nejsilnější lidé nejsou ti, kteří ukazují svou sílu veřejně, ale ti, kteří bojují se svými bitvami v tichosti.“',
    '„Když se ti něco zdá nemožné, znamená to, že to ještě nemáš dokončeno.“',
    '„Tvoje budoucnost je vytvořena tím, co děláš dnes, ne zítra.“',
    '„Být zaměstnán není totéž co být produktivní.“',
    '„Stále klad důraz na kvalitu, nikoli na kvantitu. Jedna skvělá práce je lepší než tisíc průměrných.“',
    '„Vzdávat se je jediná jistá cesta k neúspěchu.“',
    '„Pokud jsi unavený z učení, zkoušej ignoranci.“',
    '„Práce bez vizí je drudgery. Vize bez práce je jen sen.“',
    '„Pravá motivace nevychází z odměn, ale z vnitřního zápalu.“',
    '„Pokud pracuješ na něčem, co ti skutečně záleží, nemusíš být tlačen. Tvá vize tě táhne.“',
    '„Podstatné není být zaměstnán, ale být produktivní.“',
    '„Pracuj chytře, ne tvrdě.“',
    '„Překážky v cestě nejsou tam, aby nás zastavily, ale abychom jim ukázali, jak moc si toho přejeme.“',
    '„Máš to v sobě, ale je třeba se trochu zapotit.“',
    '„Kdo chce dosáhnout vrcholu, nesmí zůstat dole.“',
    '„Neexistuje cesta k úspěchu, úspěch je cesta.“',
    '„Dnešní příprava rozhoduje o zítřejším úspěchu.“',
    '„Pokud hledáš perfektní okamžik, budeš čekat navždy. Začni nyní.“',
    '„Změna je těžká na začátku, nepříjemná uprostřed, a nádherná na konci.“',
    '„Nejlepší způsob, jak předvídat budoucnost, je ji vytvořit.“',
    '„Malý pokrok je stále pokrok.“',
    '„Pokud chceš dosáhnout něčeho, co jsi ještě nikdy nedosáhl, musíš dělat něco, co jsi ještě nikdy nedělal.“',
    '„Mnoho lidí říká, že je těžké začít. Ale pravda je, že je těžké zůstat v pohybu.“',
    '„Udělej dnes to, čeho se budeš zítra moci držet.“',
    '„Překonávání sebe samého je lepší než překonávání ostatních.“',
];

const randomQuoteNumber = Math.floor(Math.random() * quotes.length);
</script>
