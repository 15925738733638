import * as Sentry from '@sentry/vue';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { createApp } from 'vue';
import primeVuePlugin from '@/plugins/primeVue/index';
import gtm from '@/services/gtm';
import App from './App.vue';
import MainButton from './modules/global/MainButton.vue';
import i18n from './plugins/i18n';
import pinia from './plugins/pinia';
import themesPlugin from './plugins/themes';
import router from './router/index';
import './assets/tailwind.css';

const app = createApp(App);

app.config.globalProperties.$filePath = import.meta.env.VITE_FILE_SYSTEM_URL;

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
    // const tracePropagationTargets = import.meta.env.+.split(',');

    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        tracePropagationTargets: [/^https:\/\/production\.dotnet\.tanganica\.com/],
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
        replaysSessionSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
        replaysOnErrorSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
    });
}

app.use(gtm);
app.use(i18n);
app.use(primeVuePlugin);
app.use(ToastService);
app.use(pinia);
app.use(router);
app.use(themesPlugin);

// Components
app.component('MainButton', MainButton);

// Directives
app.directive('tooltip', Tooltip);

app.mount('#app');
