<template>
  <div class="mb-5 flex justify-end">
    <div>
      <div class="mb-2 block w-full min-w-fit border-none px-0 dark:bg-[#28334e] lg:w-72">
        <select v-model="selectDays" id="location" name="location" class="mx-auto mt-1 w-full min-w-fit rounded-full border-gray-300 lg:w-72">
          <option value="today">
            {{ $t('statistics.pages.statistics.today') }}
          </option>
          <option value="yesterday">
            {{ $t('statistics.pages.statistics.yesterday') }}
          </option>
          <option value="last7Days">
            {{ $t('statistics.pages.statistics.last7Days') }}
          </option>
          <option value="lastMonth">
            {{ $t('statistics.pages.statistics.lastMonth') }}
          </option>
          <option value="last3Month">
            {{ $t('statistics.pages.statistics.last3Month') }}
          </option>
          <option value="last6Month">
            {{ $t('statistics.pages.statistics.last6Month') }}
          </option>
          <option value="lastYear">
            {{ $t('statistics.pages.statistics.lastYear') }}
          </option>
        </select>
      </div>
      <Datepicker
        class="relative mb-5 block w-full min-w-fit rounded-full border-none px-0 dark:bg-[#28334e] lg:w-72"
        :lang="userStore.languageCode"
        :locale="userStore.languageCode"
        :format="userStore.languageCode == 'cs' ? 'dd.MM.yyyy' : 'MM/dd/yyyy'"
        type="date"
        :selectText="$t('statistics.pages.statistics.select')"
        :cancelText="$t('statistics.pages.statistics.cancel')"
        :clearable="false"
        v-model="dates"
        :disabled-dates="disabledDates"
        :enable-time-picker="true"
        :auto-position="false"
        range
        :teleport="true"
      >
      </Datepicker>
    </div>
  </div>
  <div class="mb-4 flex w-fit items-center">
    <h1 class="text-base font-bold text-main sm:text-lg">
      {{ $t('statistics.pages.statistics.exportText') }}
    </h1>
    <StatisticsExport :dates="{ dateFrom: dates[0], dateTo: dates[1] }" statisticsType="product" :showPDF="false" :filters="lazyParams" :totalRows="totalRecords" />
  </div>
  <DataTable
    v-model:filters="filters"
    :value="products"
    filterDisplay="row"
    dataKey="id"
    showGridlines
    stripedRows
    size="small"
    paginator
    :rows="10"
    scrollable
    :rowsPerPageOptions="[5, 10, 20, 50, 100]"
    :loading="loading"
    :totalRecords="totalRecords"
    :lazy="true"
    @page="onPage($event)"
    @sort="onSort($event)"
    @filter="onFilter($event)"
    ref="dt"
    :first="first"
    :ptOptions="{ mergeProps: true }"
    :pt="{
      column: {
        headercell: ({ context, props }) => ({
          class: [''],
        }),
      },
    }"
  >
    <template #empty>
      <div class="my-auto">
        <h1 class="my-auto block text-center text-xl font-bold text-main">{{ $t('statistics.pages.statistics.productsNotFound') }}</h1>
      </div>
    </template>
    <Column sortable field="productId" :header="$t('statistics.pages.statistics.productID')">
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          size="small"
          v-model="filterModel.value"
          type="text"
          @keydown.enter="filterCallback()"
          class="p-column-filter"
          :pt-options="{ mergeProps: true }"
          :pt="{
            root: ({ props, context }) => ({
              class: [
                'w-full',
                'm-0',
                'placeholder:!text-gray-500',
                '!rounded-md',
                {
                  'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
                },
                {
                  '!text-xs px-2 !py-1': props.size === 'small',
                  'p-1 sm:!p-1 !text-xs': props.size == null,
                },
              ],
            }),
          }"
        />
      </template>
    </Column>
    <Column sortable field="productName" :header="$t('statistics.pages.statistics.productName')">
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          size="small"
          v-model="filterModel.value"
          type="text"
          @keydown.enter="filterCallback()"
          class="p-column-filter"
          :pt-options="{ mergeProps: true }"
          :pt="{
            root: ({ props, context }) => ({
              class: [
                'w-full',
                'm-0',
                'placeholder:!text-gray-500',
                '!rounded-md',
                {
                  'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
                },
                {
                  '!text-xs px-2 !py-1': props.size === 'small',
                  'p-1 sm:!p-1 !text-xs': props.size == null,
                },
              ],
            }),
          }"
        />
      </template>
    </Column>
    <Column sortable :showClear="false" :showFilterMenu="false" :show-clear-button="false" field="productRevenue" :header="$t('statistics.pages.statistics.productRevenue')">
      <template #filter="{ filterModel, filterCallback }">
        <div v-if="filterModel.value[0] !== null && filterModel.value[1] !== null">
          <span v-if="showChooseRevenue" @click="toggle" class="mx-auto cursor-pointer text-center text-base text-main hover:underline">{{ $t('statistics.pages.statistics.productsChooseRevenue') }}</span>
          <div v-else @click="toggle" class="flex max-w-32 cursor-pointer items-center hover:text-main hover:underline lg:max-w-40">
            <div class="flex items-center truncate">
              <span class="mr-1">{{ $t('statistics.pages.statistics.productsFrom') }}</span>
              <LocalizedValue :value="{ value: filterModel.value[0], currency: minProductRevenue?.currency, type: minProductRevenue?.type }"></LocalizedValue>
              <span class="mx-1">-</span>
              <span class="mr-1">{{ $t('statistics.pages.statistics.productsTo') }}</span>
              <LocalizedValue :value="{ value: filterModel.value[1], currency: minProductRevenue?.currency, type: minProductRevenue?.type }"></LocalizedValue>
            </div>
            <span>...</span>
          </div>
        </div>

        <OverlayPanel ref="op">
          <div class="flex w-[25rem] flex-col gap-3">
            <Slider v-model="filterModel.value" range class="mb-5 w-56" :min="minProductRevenue?.value" :max="maxProductRevenue.value" />
            <div class="flex items-center justify-between px-4">
              <div class="w-fit">
                <span class="mb-1 block text-xs text-main">{{ $t('statistics.pages.statistics.productsMinTurnover') }}</span>
                <InputNumber
                  v-model="filterModel.value[0]"
                  mode="currency"
                  :currency="minProductRevenue?.currency"
                  :min="minProductRevenue?.value"
                  :max="maxProductRevenue.value"
                  :locale="userStore.languageCode"
                  style="width: 150px"
                  :maxFractionDigits="0"
                  :ptOptions="{ mergeProps: true }"
                  :pt="{
                    input: {
                      root: () => {
                        return {
                          class: ['!p-2'],
                        };
                      },
                    },
                  }"
                />
              </div>
              <div class="w-fit">
                <span class="mb-1 block text-xs text-main">{{ $t('statistics.pages.statistics.productsMaxTurnover') }}</span>
                <InputNumber
                  v-model="filterModel.value[1]"
                  mode="currency"
                  :currency="maxProductRevenue?.currency"
                  :min="minProductRevenue?.value"
                  :max="maxProductRevenue.value"
                  :locale="userStore.languageCode"
                  style="width: 150px"
                  :maxFractionDigits="0"
                  :ptOptions="{ mergeProps: true }"
                  :pt="{
                    input: {
                      root: () => {
                        return {
                          class: ['!p-2'],
                        };
                      },
                    },
                  }"
                />
              </div>
            </div>
            <div class="mt-5 flex w-full justify-between">
              <button @click="cancelSelection()" class="text-main">{{ $t('statistics.pages.statistics.cancelSelection') }}</button>
              <Button label="Uložit" @click="filterCallback()" class="!p-2 !px-3"></Button>
            </div>
          </div>
        </OverlayPanel>
      </template>
      <template #body="slotProps">
        <LocalizedValue :value="slotProps.data.productRevenue"></LocalizedValue>
      </template>
    </Column>
    <Column sortable field="productQuantity" :header="$t('statistics.pages.statistics.productQuantity')"> </Column>
    <Column sortable field="orderCount" :header="$t('statistics.pages.statistics.productOrderCount')"> </Column>
  </DataTable>
</template>
<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker';
import { FilterMatchMode } from 'primevue/api';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import OverlayPanel from 'primevue/overlaypanel';
import Slider from 'primevue/slider';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch, computed } from 'vue';
import LocalizedValue from '@/modules/global/components/LocalizedValue.vue';
import StatisticsExport from '@/modules/statistics/components/StatisticsExport.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';
import { calculateDateRange } from '@/utils/statisticsCalculateDateRange';

const op = ref();
const dt = ref();
const lazyParams = ref({});
const loading = ref(false);
const totalRecords = ref(0);
const first = ref(0);
const userStore = useUserStore();
const eshopStore = useEshopsStore();
const selectDays = ref('lastMonth');
const disabledDates = ref([]);

const minProductRevenue = ref();
const maxProductRevenue = ref();

const products = ref();

const toast = useToast();
const today = new Date();
const dates = ref([new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0, 0), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)]);

const filters = ref({
  productId: {
    value: null,
    matchMode: FilterMatchMode.CONTAINS,
  },
  productName: {
    value: null,
    matchMode: FilterMatchMode.CONTAINS,
  },
  productRevenue: {
    value: [null, null],
    matchMode: 'inRange',
  },
});

const showChooseRevenue = computed(() => {
  return filters.value.productRevenue.value[0] === minProductRevenue.value?.value && filters.value.productRevenue.value[1] === maxProductRevenue.value?.value;
});

onMounted(async () => {
  loading.value = true;

  lazyParams.value = {
    dateFrom: dates.value[0],
    dateTo: dates.value[1],
    show: 'all', // 'all', 'active', 'newRegister', 'archive
    first: dt.value.first,
    rows: dt.value.rows,
    sortField: null,
    sortOrder: null,
    filters: filters.value,
    page: 0,
  };

  await setDisableDates();
  await loadLazyData();
  filters.value.productRevenue.value = [minProductRevenue.value.value, maxProductRevenue.value.value];
});

const loadLazyData = async (event?) => {
  loading.value = true;
  lazyParams.value = {
    ...lazyParams.value,
    first: event?.first || first.value,
    utcOffset: new Date().getTimezoneOffset() / -60,
  };
  lazyParams.value.dateFrom = dates.value[0];
  lazyParams.value.dateTo = dates.value[1];
  try {
    const { data } = await api.clientStatisticsGetProductStats(lazyParams.value);
    products.value = data.products;
    totalRecords.value = data.totalRows;
    minProductRevenue.value = data.minProductRevenue ? data.minProductRevenue : { value: null };
    maxProductRevenue.value = data.maxProductRevenue ? data.maxProductRevenue : { value: null };
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loading.value = false;
  }
};

const cancelSelection = async () => {
  filters.value.productRevenue.value = [minProductRevenue.value.value, maxProductRevenue.value.value];
  await loadLazyData();
  op.value.hide();
};

const onPage = (event) => {
  lazyParams.value = { ...lazyParams.value, ...event };
  loadLazyData(event);
};
const onSort = (event) => {
  lazyParams.value = { ...lazyParams.value, ...event };
  loadLazyData(event);
};
const onFilter = (event) => {
  lazyParams.value.filters = filters.value;
  loadLazyData(event);
};

const setDisableDates = () => {
  const today = new Date();
  for (let i = 1; i < 32; i++) {
    disabledDates.value.push(new Date(new Date().setDate(today.getDate() + i)));
  }
};

watch(
  () => eshopStore.getSelectedEshop?.id,
  async () => {
    await setDisableDates();
    await loadLazyData();
    filters.value.productRevenue.value = [minProductRevenue.value.value, maxProductRevenue.value.value];
  }
);

watch(
  () => dates.value,
  async (val) => {
    // watch the date
    if (dates.value && dates.value.length === 2) {
      await loadLazyData();
      filters.value.productRevenue.value = [minProductRevenue.value.value, maxProductRevenue.value.value];
    }
  }
);

watch(
  () => selectDays.value,
  async (selectedValue) => {
    const { startDate, endDate } = calculateDateRange(selectedValue);

    dates.value = [startDate, endDate];
    await loadLazyData();
  }
);

const toggle = (event) => {
  op.value.toggle(event);
};
</script>
