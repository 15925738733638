<template>
  <h1 class="mb-2 mr-2 inline-flex flex-1 text-sm font-bold text-gray-800 dark:bg-mainDark md:text-xl lg:text-2xl">{{ $t('rechargeCredit.billingInfo.title') }}</h1>
  <form @submit.prevent="onSubmit">
    <div class="mb-3 grid w-full grid-cols-1 gap-x-8 gap-y-1 text-left sm:grid-cols-2 lg:gap-y-4">
      <div class="mt-2 sm:col-span-1">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyName">{{ $t('rechargeCredit.billingInfo.company') }}<span class="ml-1 text-red-600">*</span></label>
        <InputText id="companyName" v-model="companyName" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="organization" />
        <small id="text-error" class="text-sm text-red-600">{{ errors.companyName || '&nbsp;' }}</small>
      </div>
      <div class="mt-2 sm:col-span-1">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyAddress">{{ $t('rechargeCredit.billingInfo.street') }}<span class="ml-1 text-red-600">*</span></label>
        <InputText id="companyAddress" v-model="companyAddress" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="street-address" />
        <small id="text-error" class="text-sm text-red-600">{{ errors.companyAddress || '&nbsp;' }}</small>
      </div>
      <div class="mt-2 sm:col-span-1">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyCity">{{ $t('rechargeCredit.billingInfo.city') }}<span class="ml-1 text-red-600">*</span></label>
        <InputText id="companyCity" v-model="companyCity" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="address-level2" />
        <small id="text-error" class="text-sm text-red-600">{{ errors.companyCity || '&nbsp;' }}</small>
      </div>
      <div class="mt-2 sm:col-span-1">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyZipCode">{{ $t('rechargeCredit.billingInfo.postalCode') }}<span class="ml-1 text-red-600">*</span></label>
        <InputText id="companyZipCode" v-model="companyZipCode" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="postal-code" />
        <small id="text-error" class="text-sm text-red-600">{{ errors.companyZipCode || '&nbsp;' }}</small>
      </div>
      <div class="mt-2 sm:col-span-1">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyId">{{ $t('rechargeCredit.billingInfo.companyId') }}<span class="ml-1 text-red-600">*</span></label>
        <InputText id="companyId" v-model="companyId" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="company-id" />
        <small id="text-error" class="text-sm text-red-600">{{ errors.companyId || '&nbsp;' }}</small>
      </div>
      <div class="mt-2 sm:col-span-1">
        <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="companyVat">{{ $t('rechargeCredit.billingInfo.vatNumber') }}</label>
        <InputText id="companyVat" v-model="companyVat" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="company-vat" />
        <small id="text-error" class="text-sm text-red-600">{{ errors.companyVat || '&nbsp;' }}</small>
      </div>
      <div class="mt-2 sm:col-span-1">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base">{{ $t('rechargeCredit.billingInfo.country') }}<span class="ml-1 text-red-600">*</span></label>
        <SelectCountry v-model="country" />
        <small id="text-error" class="text-sm text-red-600">{{ errors.country || '&nbsp;' }}</small>
      </div>
      <div class="mt-2 sm:col-span-1">
        <label class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base" for="facturationalEmail">{{ $t('rechargeCredit.billingInfo.email') }}</label>
        <InputText id="facturationalEmail" v-model="facturationalEmail" :placeholder="$t('wizard.fillFieldPlaceholder')" autocomplete="bill-email" />
        <small id="text-error" class="text-sm text-red-600">{{ errors.facturationalEmail || '&nbsp;' }}</small>
      </div>
    </div>
    <div class="ml-auto mr-0 w-fit">
      <MainButton type="submit" :text="$t('rechargeCredit.billingInfo.save')" />
    </div>
  </form>
</template>
<script setup lang="ts">
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { useForm } from 'vee-validate';
import { onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CountriesCodebook from '@/codebooks/CountriesCodebook';
import SelectCountry from '@/modules/global/components/SelectCountry.vue';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { EnumsCountryOfAdvertisement } from '../../../../generated/api';

onMounted(async () => {
  await getBillingSettings();
});

const toast = useToast();
const ladingStore = useLoadingStore();
const eshopsStore = useEshopsStore();
const { t } = useI18n();

watch(
  () => eshopsStore.selectedEshop?.id,
  async () => {
    await getBillingSettings();
  }
);

const { defineField, handleSubmit, errors, resetForm } = useForm({
  validationSchema: yup.object({
    companyName: yup.string().required(() => t('validations.required')),
    companyAddress: yup.string().required(() => t('validations.required')),
    companyCity: yup.string().required(() => t('validations.required')),
    companyZipCode: yup.string().required(() => t('validations.required')),
    companyId: yup
      .string()
      .required(() => t('validations.required'))
      .companyID(),
    companyVat: yup.string().notRequired().vatID(),
    country: yup.object().required(() => t('validations.required')),
    facturationalEmail: yup.string().email(() => t('validations.email')),
  }),
});

// Form fields
const [companyName] = defineField('companyName');
const [companyAddress] = defineField('companyAddress');
const [companyCity] = defineField('companyCity');
const [companyZipCode] = defineField('companyZipCode');
const [companyId] = defineField('companyId');
const [companyVat] = defineField('companyVat');
const [country] = defineField('country');
const [facturationalEmail] = defineField('facturationalEmail');

const onSubmit = handleSubmit(async (formData) => {
  const payload = {
    companyName: formData.companyName,
    companyAddress: formData.companyAddress,
    companyCity: formData.companyCity,
    companyZipCode: formData.companyZipCode,
    companyId: formData.companyId,
    companyVat: formData.companyVat || null,
    country: formData.country?.code as EnumsCountryOfAdvertisement,
    facturationalEmail: formData.facturationalEmail,
  };
  await saveBillingSettings(payload);
});

const getBillingSettings = async () => {
  try {
    const { data } = await api.clientPaymentHistoryGetBillingSettings();
    data.country = CountriesCodebook.find((country) => country.code === data.country) ?? null;
    resetForm({ values: data });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const saveBillingSettings = async (payload) => {
  ladingStore.updateLoading(true);
  try {
    await api.clientPaymentHistorySetBillingSettings(payload);
    toast.add({
      severity: 'success',
      summary: t('rechargeCredit.billingInfo.saveBillingInfoSuccessTitle'),
      life: 3000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    ladingStore.updateLoading(false);
  }
};
</script>
