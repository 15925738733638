/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsMarketingFormat = {
    Pla: 'PLA',
    Prx: 'PRX',
    Dsa: 'DSA',
    Dpa: 'DPA',
    Historic: 'Historic'
} as const;

export type EnumsMarketingFormat = typeof EnumsMarketingFormat[keyof typeof EnumsMarketingFormat];



