export enum CLIENT_ROUTES {
  STATISTICS_PAGE = 'routesStatisticsPage',
  RECHARGE_CREDIT_PAGE = 'routesRechargeCreditPage',
  PRIORITIZATION_PAGE = 'routesPrioritizationPage',
  AFFILIATE_REPORT_PAGE = 'routesAffiliateReportPage',
  ESHOP_SETTINGS_PAGE = 'routesEshopSettingsPage',
  SUPPORT_PAGE = 'routesSupportPage',
  CONTACT_PAGE = 'routesContactPage',
  USER_SETTINGS_PAGE = 'routesUserSettingsPage',
  FACEBOOK_PAGE_SHARE_CALLBACK_PAGE = 'routesFacebookPageShareCallbackPage',
  FACEBOOK_BUSINESS_SHARE_CALLBACK_PAGE = 'routesFacebookBusinessShareCallbackPage',
  DASHBOARD_PAGE = 'routesDashboardPage',
  LOGOUT_PAGE = 'routesLogoutPage',
  ESHOP_LIST_PAGE = 'routesEshopListPage',
  STRIPE_SUCCESS_CALLBACK_PAGE = 'routesStripeSuccessCallbackPage',
  PAYMENT_PAGE = 'routesPaymentPage',
  BILLING_INFO_PAGE = 'routesBillingInfoPage',
  PAYMENT_HISTORY_PAGE = 'routesPaymentHistoryPage',
  PAYMENT_GATEWAY_PAGE = 'Checkout',
  PAYMENT_BANK_TRANSFER_PAGE = 'BankTransfer',
  PAYMENT_AUTOMATIC_RECHARGE_PAGE = 'AutomaticRecharge',
  CAMPAIGN_STATISTICS_PAGE = 'routesCampaignStatisticsPage',
  ORDERS_STATISTICS_PAGE = 'routesOrdersStatisticsPage',
  PRODUCTS_STATISTICS_PAGE = 'routesProductsStatisticsPage',
  TRAFFIC_STATISTICS_PAGE = 'routesTrafficStatisticsPage',
  TRAFFIC_VISIT_SOURCES_STATISTICS_PAGE = 'routesTrafficVisitSourcesStatisticsPage',
  TRAFFIC_DIRECTORY_STATISTICS_PAGE = 'routesTrafficDirectoryStatisticsPage',
  COMPETITIVE_METRICS_STATISTICS_PAGE = 'routesCompetitiveMetricsStatisticsPage',
  CATEGORIES_STATISTICS_PAGE = 'routesCategoriesStatisticsPage',
}
