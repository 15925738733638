import { useJwt } from '@vueuse/integrations/useJwt';
import { defineStore } from 'pinia';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';

interface State {
  userAccessToken: string | null;
  eshopAccessToken: string | null;
  refreshToken: string | null;
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    userAccessToken: null,
    eshopAccessToken: null,
    refreshToken: null,
  }),
  getters: {
    getUserAccessToken: (state) => state.userAccessToken,
    getEshopAccessToken: (state) => state.eshopAccessToken,
    getRefreshToken: (state) => state.refreshToken,
  },
  actions: {
    setUserAccessToken(accessToken: string) {
      this.userAccessToken = accessToken;
    },
    setEshopAccessToken(accessToken: string) {
      this.eshopAccessToken = accessToken;
    },
    setRefreshToken(refreshToken: string) {
      this.refreshToken = refreshToken;
    },
    async refreshUserAccessToken() {
      const userStore = useUserStore();

      try {
        const { data: clientAuthGetUserAccessTokenResponse } = await api.clientAuthGetUserAccessToken({
          headers: {
            Authorization: `Bearer ${this.refreshToken}`,
          },
          metadata: {
            requestType: RequestTypeEnum.REFRESH_TOKEN,
          },
        });

        if (clientAuthGetUserAccessTokenResponse?.accessToken) {
          this.setUserAccessToken(clientAuthGetUserAccessTokenResponse.accessToken);

          // Update email, affiliate and provider from token
          const { payload } = useJwt(clientAuthGetUserAccessTokenResponse.accessToken);
          const decodedToken = payload.value;
          if (decodedToken) {
            userStore.setEmail(decodedToken.email);
            userStore.setAffiliate(decodedToken.role.includes('Affil'));
            userStore.setAuthProvider(decodedToken.AuthProvider);
          }

          return clientAuthGetUserAccessTokenResponse.accessToken;
        } else {
          return '';
        }
      } catch (error) {
        return '';
      }
    },
    async refreshEshopAccessToken() {
      const eshopsStore = useEshopsStore();

      try {
        const { data: clientAuthGetEshopAccessTokenResponse } = await api.clientAuthGetEshopAccessToken(
          {
            eshopId: eshopsStore.getSelectedEshop?.id || '',
          },
          {
            headers: {
              Authorization: `Bearer ${this.refreshToken}`,
            },
            metadata: {
              requestType: RequestTypeEnum.REFRESH_TOKEN,
            },
          }
        );

        // Update the auth store
        if (clientAuthGetEshopAccessTokenResponse.accessToken) {
          this.setEshopAccessToken(clientAuthGetEshopAccessTokenResponse.accessToken);
          return clientAuthGetEshopAccessTokenResponse.accessToken;
        } else {
          return '';
        }
      } catch (error) {
        return '';
      }
    },
    destroySession() {
      this.reset();

      const userStore = useUserStore();
      const eshopsStore = useEshopsStore();

      userStore.destroySession();
      eshopsStore.resetEshops();
    },
    reset() {
      this.$reset();
    },
  },
  persist: ['userAccessToken', 'eshopAccessToken', 'refreshToken']
});
