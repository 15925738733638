import messages from '@intlify/unplugin-vue-i18n/messages';
import { isRef, nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import type { I18n, I18nOptions, Locale, VueI18n, Composer, I18nMode } from 'vue-i18n';

const datetimeFormats: I18nOptions['datetimeFormats'] = {
  cs: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  },
  sk: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  },
  en: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  },
};

const numberFormats: I18nOptions['numberFormats'] = {
  cs: {
    currency: {
      style: 'currency',
      currency: 'CZK',
      useGrouping: true,
      notation: 'standard',
      currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', // Format as a percentage
      minimumFractionDigits: 2, // Minimum number of decimal places
      maximumFractionDigits: 2, // Maximum number of decimal places
      useGrouping: false,
    },
  },
  sk: {
    currency: {
      style: 'currency',
      currency: 'CZK',
      useGrouping: true,
      notation: 'standard',
      currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', // Format as a percentage
      minimumFractionDigits: 2, // Minimum number of decimal places
      maximumFractionDigits: 2, // Maximum number of decimal places
      useGrouping: false,
    },
  },
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      notation: 'standard',
      currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal',
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 2,
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 2, // Minimum number of decimal places
      maximumFractionDigits: 2, // Maximum number of decimal places
      useGrouping: false,
    },
  },
};

const i18nConfig = {
  legacy: false,
  useScope: 'global',
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
  datetimeFormats,
  numberFormats,
};

function isComposer(instance: VueI18n | Composer, mode: I18nMode): instance is Composer {
  return mode === 'composition' && isRef(instance.locale);
}

export function setLocale(i18n: I18n, locale: Locale): void {
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale;
  } else {
    i18n.global.locale = locale;
  }
}

const i18n = setupI18n(i18nConfig);

export function setupI18n(options: I18nOptions): I18n {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale!);
  return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: Locale): void {
  setLocale(i18n, locale);
  // api.defaults.headers.common['Accept-Language'] = locale;
  document.querySelector('html')!.setAttribute('lang', locale);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getResourceMessages = (r: any) => r.default || r;

export async function loadLocaleMessages(i18n: I18n, locale: Locale) {
  // load locale messages
  const messages = await import(`../translations/${locale}.json`).then(getResourceMessages);

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages);

  return nextTick();
}

export default i18n;
